import React from "react";
import { Hero } from "./components";
import { Footer, Header } from "../../layout";
import Frame from "./components/Frame";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { localeRoutes } from "../../locale/localeRoutes";

const SchedulePage = () => {
  const { t, i18n } = useTranslation("meta");

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t("schedule.title")}</title>
        <meta name="description" content={t("schedule.description")} data-react-helmet="true" />
        <link href="https://linktou.com.br/agende-uma-conversa" rel="canonical"></link>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "LinkToU",
            alternateName: t("schedule.alternateName"),
            logo: process.env.ASSETS_PATH + `/imgs/logo-l2u.svg`,
            brand: {
              "@type": "Thing",
              name: "LinkToU",
            },
          })}
        </script>
        <script
          type="text/javascript"
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        ></script>
      </Helmet>
      <Header altColors={true} routes={localeRoutes({ page: "schedule" })} />
      <Hero />
      <Frame />
      <Footer />
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  element: <SchedulePage />,
  validateRoute: async (dispatch, page, store, path, matchPath, languages, localeRoutes) => {
    // await dispatch(fetchHomePage());
    let lang = path === "/agende-uma-conversa" ? "br" : path.split("/")[1];
    const routeExists = localeRoutes({ page: "schedule" })[lang] !== undefined;

    return new Promise((resolve, reject) => {
      if (languages.includes(lang) && routeExists) resolve({ isValid: true });
      else resolve({ isValid: false });
    });
  },
};
