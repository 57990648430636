import React, { useRef } from "react";
import { Button, Heading, Image, useIsVisible } from "linktou.ds.components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { localeRoutes } from "../../../locale/localeRoutes";
import { twMerge } from "tailwind-merge";

export const Brands = () => {
  const { t, i18n } = useTranslation("home");

  const brands = [
    {
      img: "clear.png",
      name: "Clear Corretora",
    },
    {
      img: "bancomaster.png",
      name: "Banco Master",
    },
    {
      img: "nubank.png",
      name: "Nubank",
    },
    {
      img: "hi.png",
      name: "Hi Platform",
    },
    {
      img: "mackenzie.png",
      name: "Universidade Presbiteriana Mackenzie",
    },
    {
      img: "unicsul.png",
      name: "Universidade Cruzeiro do Sul",
    },
    {
      img: "sky.png",
      name: "SKY",
    },
    {
      img: "torra.png",
      name: "Lojas Torra",
    },
    {
      img: "rihappy.png",
      name: "RiHappy Brinquedos",
    },

    {
      img: "totalexpress.png",
      name: "Total Express",
    },
    {
      img: "vr.png",
      name: "VR Vale Refeição",
    },
    {
      img: "enxuto.png",
      name: "Enxuto",
    },
    {
      img: "lamoda.png",
      name: "Grupo LaModa",
    },
    // {
    //   img: "honeybe.png",
    //   name: "Honey Be",
    // },
  ];

  const slice1 = brands.slice(0, 4);
  const slice2 = brands.slice(4, 8);
  const slice3 = brands.slice(8, 13);

  const ref = useRef();
  const ref2 = useRef();
  const isVisible = useIsVisible(ref, true);
  const isVisible2 = useIsVisible(ref2, true);

  return (
    <div className="relative flex justify-evenly items-center w-full min-h-[600px] px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
      <div ref={ref} className="flex flex-col justify-evenly items-center w-full h-full max-w-7xl gap-4 tablet:gap-2">
        <Heading
          variant="h2"
          className={twMerge(
            "w-full text-center tablet:gap-10 font-bold text-4xl tablet:text-2xl text-primary-400 z-10 opacity-0",
            isVisible && "animate-slideUp opacity-100"
          )}
        >
          {t("brands.title")}
        </Heading>

        <div
          ref={ref2}
          className={twMerge(
            "flex justify-around items-center h-full flex-wrap gap-14 tablet:gap-6 mobile:gap-8 p-8 mobile:px-0"
          )}
        >
          {slice1.map((brand) => {
            return (
              <Image
                key={brand.name}
                className={twMerge(
                  `full:h-[80px] h-[60px] tablet:h-[40px] mobile:h-[35px] mobile:last-of-type:hidden desktop:mx-2 full:mx-2 opacity-0`,
                  isVisible2 && `animate-slideUp-2 opacity-100`
                )}
                src={process.env.ASSETS_PATH + "/brands/" + brand.img}
                alt={brand.name}
              />
            );
          })}
          {slice2.map((brand) => {
            return (
              <Image
                key={brand.name}
                className={twMerge(
                  `full:h-[80px] h-[60px] tablet:h-[40px] mobile:h-[35px] mobile:last-of-type:hidden desktop:mx-2 full:mx-2 opacity-0`,
                  isVisible2 && `animate-slideUp-3 opacity-100`
                )}
                src={process.env.ASSETS_PATH + "/brands/" + brand.img}
                alt={brand.name}
              />
            );
          })}
          {slice3.map((brand) => {
            return (
              <Image
                key={brand.name}
                className={twMerge(
                  `full:h-[80px] h-[60px] tablet:h-[40px] mobile:h-[35px] mobile:last-of-type:hidden desktop:mx-2 full:mx-2 opacity-0`,
                  isVisible2 && `animate-slideUp-4 opacity-100`
                )}
                src={process.env.ASSETS_PATH + "/brands/" + brand.img}
                alt={brand.name}
              />
            );
          })}
        </div>

        <Link
          to={localeRoutes({page: "schedule"})[i18n.language]}
          className="flex justify-center items-center center w-full z-10"
        >
          <Button size="lg">{t("brands.cta")}</Button>
        </Link>

        <div className="bg-primary-100 animate-boxRandom2 rounded-lg absolute left-[50px] top-[-32px] w-[60px] h-[60px] opacity-20" />
        <div className="bg-primary-100 animate-boxRandom1 rounded-lg absolute left-[80px] top-[0px] w-[120px] h-[120px] opacity-20" />
      </div>
    </div>
  );
};
