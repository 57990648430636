import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import { resources } from "./resources";
i18n.use(Backend).use(initReactI18next).use(LanguageDetector).init({
  resources: resources,
  fallbackLng: ["br", "en", "es"],
  supportedLngs: ["br", "en", "es"],
  debug: false,
  returnObjects: true,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
});

export default i18n;