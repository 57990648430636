import React, { useRef } from "react";
import {
  Button,
  Heading,
  Image,
  Text,
  useIsVisible,
} from "linktou.ds.components";
import { TFunction, i18n } from "i18next";
import { Link } from "react-router-dom";
import { localeRoutes } from "../../../locale/localeRoutes";
import { twMerge } from "tailwind-merge";

interface ArticleProps {
  t: TFunction;
  i18n: i18n;
}

export const Article4 = ({ t, i18n }: ArticleProps) => {
  const ref = useRef();
  const isVisible = useIsVisible(ref, true);

  return (
    <div className="flex flex-col justify-center items-center min-h-[400px] px-[160px] gap-16 tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px] ">
      <div
        id="wrapper"
        ref={ref}
        className="flex flex-col justify-center items-center full:max-w-7xl"
      >
        <div className="flex tablet:flex-col-reverse justify-between items-center gap-16 tablet:gap-12">
          <div
            className={twMerge(
              "flex justify-center items-center w-1/2 tablet:w-full opacity-0",
              isVisible && "animate-slideUp-2 opacity-100"
            )}
          >
            <Image
              className=""
              src={process.env.ASSETS_PATH + "/imgs/metricsFlowPreview.png"}
              alt=""
            />
          </div>
          <div
            className={twMerge(
              "flex flex-col justify-center items-start w-1/2 text-left gap-3 tablet:w-full opacity-0",
              isVisible && "animate-slideUp-3 opacity-100"
            )}
          >
            <Heading
              variant="h2"
              className="w-full font-semibold text-[28px] tablet:text-2xl text-primary-400 mb-4"
            >
              {t("article.fourthArticle.title")}
            </Heading>
            <Text className="text-base text-primary-800">
              {t("article.fourthArticle.sub-title")}
            </Text>
          </div>
        </div>
        <div className="flex justify-center items-center w-full px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
          <Link
            to={localeRoutes({ page: "schedule" })[i18n.language]}
            className="flex justify-center items-center w-full z-10"
          >
            <Button size="lg">{t("article.button")}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
