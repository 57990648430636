/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { Feedbacks, Hero, Integrations, Ipass, Brands } from "./components";
import { useAppDispatch, useAppSelector } from "../../store";
import { Helmet } from "react-helmet";
import { clearPageFetcher, fetchHome } from "../pageFetcherSlice";
import { Footer, Header } from "../../layout";
import { Connectors } from "../../common/Connectors";
import { Cards } from "../../common/Cards";
import { Banner } from "../../common/Banner";
import { useTranslation } from "react-i18next";
import { RouteMatch } from "react-router-dom";
import { localeRoutes } from "../../locale/localeRoutes";
import WhatsAppButton from "../../common/WhatsAppButton";

const HomePage = () => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation("meta");

  const { pageFetcher } = useAppSelector((store) => ({
    pageFetcher: store.pageFetcher,
  }));

  useEffect(() => {
    if (pageFetcher.status === "idle") dispatch(fetchHome());
    return () => {
      if (pageFetcher.status === "fulfilled") dispatch(clearPageFetcher());
    };
  }, [pageFetcher.status]);

  const cardsProps = {
    title: t("home.title", { ns: "cards" }),
    subtitle: undefined,
    image1: "APROVEITE1.png",
    text1: t("home.text1", { ns: "cards" }),
    image2: "APROVEITE2.png",
    text2: t("home.text2", { ns: "cards" }),
    image3: "APROVEITE3.png",
    text3: t("home.text3", { ns: "cards" }),
    buttonText: t("home.cta", { ns: "cards" }),
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t("home.title")}</title>
        <meta name="description" content={t("home.description")} />
        <link href="https://linktou.com.br/blog" rel="canonical"></link>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "LinkToU",
            alternateName: t("home.alternateName"),
            url: "https://linktou.com.br/",
            logo: process.env.ASSETS_PATH + `/imgs/logo-l2u.svg`,
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+5511935853846",
              contactType: "customer service",
              areaServed: "BR",
              availableLanguage: ["Portuguese", "en"],
            },
            address: {
              "@type": "PostalAddress",
              streetAddress: "R. Dr. Geraldo Campos Moreira, 17 - Cidade Monções",
              addressLocality: "São Paulo",
              addressRegion: "SP",
              postalCode: "04571-020",
              addressCountry: "BRA",
            },
            keywords: t("home.keywords"),
            sameAs: [
              "https://www.linkedin.com/company/linktou",
              "https://www.instagram.com/linktoubr/",
            ],
          })}
        </script>
      </Helmet>
      <WhatsAppButton />
      <Header routes={localeRoutes({ page: "home" })} />
      <Hero />
      <Connectors />
      <Brands />
      <Cards props={cardsProps} />
      <Integrations />
      <Feedbacks />
      <Ipass />
      <Banner />
      <Footer />
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  element: <HomePage />,
  validateRoute: async (
    dispatch,
    page: RouteMatch<string>,
    store,
    path: string,
    matchPath,
    languages: string[],
  ) => {
    let lang = path === "/" ? "br" : path.split("/")[1];
    const routeExists = localeRoutes({ page: "home" })[lang] !== undefined;

    return new Promise(async (resolve, reject) => {
      if (languages.includes(lang) && routeExists) {
        await Promise.all([dispatch(fetchHome())]);
        resolve({ isValid: true });
      } else resolve({ isValid: false });
    });
  },
};
