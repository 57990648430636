// import { Heading } from 'linktou.ds.components'
import React from "react";
import { Button, Text } from "linktou.ds.components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { localeRoutes } from "../../../locale/localeRoutes";

export const Hero = () => {
  const { t, i18n } = useTranslation('home')

  return (
    <div className="flex justify-center items-center w-full h-[780px] bg-primary-800">
      <div className="relative flex justify-between items-center w-full max-w-[1600px] h-full text-white bg-primary-800">
        
        <div className="flex flex-col gap-8 mobile:gap-4 w-full pl-40 tablet:pl-20 mobile:pl-[24px] tablet:mt-20 mobile:mt-24">
          <h1 className="text-4xl mobile:text-2xl max-w-[50%] tablet:max-w-[80%] leading-snug font-semibold tracking-wide z-20 my-0">
            <Text className="text-secondary-400">{t('hero.heading1')}</Text> {t('hero.heading2')}
          </h1>
          <Text className="max-w-[40%] tablet:max-w-[80%] text-white">
          {t('hero.description')}
          </Text>
          <Link to={localeRoutes({page: "schedule"})[i18n.language]} className="z-20 max-w-[350px]">
            <Button
              colorScheme="secondary"
              size="lg"
              className="z-30 mt-4 desktop:mt-0 tablet:mt-20 mobile:mt-4 mobile:px-0"
            >
              {t('hero.cta')}
            </Button>
          </Link>
        </div>

        <div className="flex justify-end h-[80%]">
          <img
            className="tablet:hidden absolute top-[180px] right-[143px] z-10"
            src={process.env.ASSETS_PATH + "/imgs/homeHeroPattern.png"}
            alt=""
          />
          <img
            className="desktop:hidden mobile:hidden absolute top-[180px] right-[60px] z-10"
            src={process.env.ASSETS_PATH + "/imgs/homeHeroTabletPattern.png"}
            alt=""
          />
          <img
            className="desktop:hidden tablet:hidden mobile:flex absolute top-[180px] right-[40px] z-10"
            src={process.env.ASSETS_PATH + "/imgs/homeHeroMobilePattern.png"}
            alt=""
          />

          {/* NODE VTEX */}
          <div className='animate-node1 bg-primary-800 border-secondary-400 border-2 z-20 rounded-md absolute right-[500px] tablet:right-[203px] mobile:right-[96px] top-[130px] tablet:top-[670px] mobile:top-[659px] w-[105px] tablet:w-[85px] h-[105px] tablet:h-[85px] flex justify-center items-center'>
            <img alt='VTEX Integration' src={process.env.ASSETS_PATH + '/nodes/vtex.png'} />
            <div className='flex absolute w-3 h-3 rounded-lg bg-functional-300 right-[-8px] tablet:right-[34px] mobile:right-[-8px] tablet:bottom-[76px] mobile:bottom-[36px]' />
          </div>

          {/* NODE SAP */}
          <div className='tablet:hidden animate-node2 bg-primary-800 border-secondary-400 border-2 z-20 rounded-md absolute right-[300px] top-[290px] w-[85px] h-[85px] flex justify-center items-center'>
            <img alt='SAP Integration' src={process.env.ASSETS_PATH + '/nodes/sap.png'} />
            <div className='flex absolute w-3 h-3 rounded-lg bg-functional-300 top-[-8px]' />
          </div>

          {/* NODE SALESFORCE */}
          <div className='animate-node3 bg-primary-800 border-secondary-400 border-2 z-20 rounded-md absolute right-[436px] tablet:right-[130px] mobile:right-[80px] top-[456px] tablet:top-[130px] w-[105px] h-[105px] flex justify-center items-center'>
            <img alt='SALESFORCE Integration' src={process.env.ASSETS_PATH + '/nodes/salesforce.png'} />
            <div className='flex absolute w-3 h-3 rounded-lg bg-functional-300 left-[-8px] tablet:left-[97px]' />
          </div>

          {/* NODE LINX */}
          <div className='tablet:hidden animate-node5 bg-primary-800 border-secondary-400 border-2 z-20 rounded-md absolute right-[200px] top-[556px] w-[85px] h-[85px] flex justify-center items-center'>
            <img alt='LINX Integration' src={process.env.ASSETS_PATH + '/nodes/linx.png'} />
            <div className='flex absolute w-3 h-3 rounded-lg bg-functional-300 left-[-8px] mobile:left-[95%]' />
          </div>

          {/* NODE TOTVS */}
          <div className='mobile:hidden animate-node4 bg-primary-800 border-secondary-400 border-2 z-20 rounded-md absolute right-[100px] tablet:right-[20px] top-[380px] w-[85px] h-[85px] flex justify-center items-center'>
            <img alt='TOTVS Integration' src={process.env.ASSETS_PATH + '/nodes/totvswhite.png'} />
            <div className='flex absolute w-3 h-3 rounded-lg bg-functional-300 left-[-8px] tablet:left-[34px] tablet:top-[-8px]' />
          </div>
        </div>

        <img
          className="absolute tablet:scale-75 right-[12.5%] tablet:right-[80%] top-[35%] tablet:top-[22%]"
          src={process.env.ASSETS_PATH + "/imgs/worldProp.png"}
          alt=""
        />
        <img
          className="absolute tablet:scale-75 right-[25%] tablet:right-[3%] mobile:left-[10%] bottom-[12.5%] tablet:bottom-[8%]"
          src={process.env.ASSETS_PATH + "/imgs/bracketsProp.png"}
          alt=""
        />

        {/* linha da esquerda */}
        <img
          className="tablet:hidden mobile:hidden tablet:left-[-30px] absolute left-[5px] top-[250px] z-10"
          src={process.env.ASSETS_PATH + "/imgs/homeHeroLeftLine.png"}
          alt="hero left line"
        />
        <img
          className="desktop:hidden mobile:hidden absolute left-[32px] top-[280px] z-10"
          src={process.env.ASSETS_PATH + "/imgs/homeHeroLeftLineTablet.png"}
          alt="hero left line"
        />
        <div className="mobile:hidden flex absolute w-4 h-4 rounded-lg bg-functional-300 left-[124px] tablet:left-[26px] top-[245px] tablet:top-[265px]  z-10" />

        {/* quadradinhos esquerda */}
        <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute left-[80px] top-[160px] w-[120px] h-[120px] opacity-20" />
        <div className="bg-primary-600 animate-boxRandom2 rounded-lg absolute left-[50px] top-[130px] w-[60px] h-[60px] opacity-20" />

        {/* quadradinhos da direita */}
        <div className="tablet:hidden bg-primary-600 animate-boxRandom3 rounded-2xl absolute right-20 w-[570px] h-[570px] opacity-20" />

        <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute right-[380px] top-[220px] w-[160px] h-[160px] opacity-20" />
        <div className="bg-primary-600 animate-boxRandom2 rounded-lg absolute right-[30px] mobile:left-[2%] bottom-[140px] tablet:bottom-[60px] w-[140px] h-[140px] opacity-20" />
        <div className="bg-primary-600 animate-boxRandom3 rounded-lg absolute right-[60px] top-[320px] w-[80px] h-[80px] opacity-20" />
      </div>
    </div>
  );
};
