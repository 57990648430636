import React, { useRef } from "react";
import {
  Button,
  Heading,
  Image,
  Text,
  useIsVisible,
} from "linktou.ds.components";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { localeRoutes } from "../../../locale/localeRoutes";
import { Link } from "react-router-dom";

export const Integrations = () => {
  const { t, i18n } = useTranslation("home");
  const ref = useRef();
  const isVisible = useIsVisible(ref, true);

  const connectors = [
    {
      img: "vtex.png",
      name: "VTEX",
    },
    {
      img: "zendesk.png",
      name: "Zendesk",
    },
    {
      img: "salesforce.png",
      name: "Salesforce",
    },
    {
      img: "linx.png",
      name: "Linx",
    },
    {
      img: "magento.png",
      name: "Magento",
    },
    {
      img: "sap.png",
      name: "SAP",
    },
    {
      img: "totvs.png",
      name: "TOTVS",
    },
    {
      img: "rdstation.png",
      name: "RD Station",
    },
  ];

  const sectors = [
    {
      title: t("integrations.sectors.ecommerce.title"),
      image: "/imgs/vendas.png",
      description: t("integrations.sectors.ecommerce.description"),
      animation: "animate-slideUp-3",
    },
    {
      title: t("integrations.sectors.marketing.title"),
      image: "/imgs/marketing.png",
      description: t("integrations.sectors.marketing.description"),
      animation: "animate-slideUp-4",
    },
    {
      title: t("integrations.sectors.finance.title"),
      image: "/imgs/financeiro.png",
      description: t("integrations.sectors.finance.description"),
      animation: "animate-slideUp-5",
    },
  ];

  return (
    <div className="relative flex flex-col justify-center items-center w-full h-[759px] tablet:h-[1122px] mobile:h-full bg-primary-700 gap-8 px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
      <div
        ref={ref}
        className="full:relative flex flex-col justify-center items-center w-full max-w-7xl"
      >
        <Image
          className="absolute full:-left-[120px] top-[35%] full:top-[25%] left-0 tablet:hidden"
          src={process.env.ASSETS_PATH + "/imgs/homeSolutionsLines.png"}
          alt=""
        />

        <div className="flex justify-center items-center whitespace-nowrap w-full carouselMD:w-[80%] carouselMD:justify-start carouselSM:justify-start carouselSM:w-[80%] mb-8">
          <Heading
            variant={"h2"}
            className={twMerge(
              "text-4xl tablet:text-[28px] mobile:text-[20px] font-semibold text-secondary-400 z-20",
              isVisible && "animate-slideUp"
            )}
          >
            {t("integrations.title")}
          </Heading>
        </div>

        <div className="flex tablet:flex-col justify-between items-center tablet:items-center gap-20 tablet:gap-5 w-full tablet:w-[80%] mobile:w-full z-10">
          <div
            className={twMerge(
              "flex flex-col justify-center items-center w-[456px] tablet:w-[560px] mobile:w-full h-full text-left gap-8",
              isVisible && "animate-slideUp-2 opacity-100"
            )}
          >
            <Text className="text-white text-lg">
              {t("integrations.description")}
            </Text>

            <div className="grid grid-cols-4 grid-rows-2 justify-items-center z-10 w-[456px] tablet:w-[560px] mobile:w-full rounded-lg bg-primary-200 gap-4 p-4 tablet:py-4 tablet:px-[68px] mobile:px-2 mobile:gap-2">
              {connectors.map((connector) => {
                return (
                  <div
                    key={connector.name + "node"}
                    className="flex flex-col justify-center gap-2 items-center aspect-square w-[88px] h-[88px] tablet:w-[88px] tablet:h-[88px] mobile:w-full mobile:h-full bg-functional-100 rounded-lg shadow-200"
                  >
                    <Image
                      className="w-[70%] h-[70%] object-contain p-2"
                      src={process.env.ASSETS_PATH + "/nodes/" + connector.img}
                      alt={connector.name}
                    />
                    <Text className="text-sm mobile:text-xs text-center -mt-3">
                      {connector.name}
                    </Text>
                  </div>
                );
              })}
            </div>
            <Link
              to={localeRoutes({ page: "schedule" })[i18n.language]}
              className="w-full flex justify-center items-center mobile:px-4 z-10 tablet:hidden"
            >
              <Button
                colorScheme="secondary"
                size="lg"
                className="z-30 desktop:mt-0 mt-4 mobile:px-0"
              >
                {t("integrations.cta")}
              </Button>
            </Link>
          </div>

          <div className="flex flex-col justify-center items-center z-10 gap-6">
            {sectors.map((item, index) => {
              return (
                <div
                  key={item.title}
                  className={twMerge(
                    "flex flex-col justify-center items-start w-[500px] h-[128px] tablet:w-[560px] mobile:w-[100%] mobile:h-fit mobile:py-1 bg-white rounded-xl gap-2 px-3 opacity-50",
                    isVisible && `${item.animation} opacity-100`
                  )}
                >
                  <Heading
                    variant="h4"
                    className="flex justify-start items-center w-full text-primary-400 font-semibold text-base mobile:text-lg"
                  >
                    {item.title}
                  </Heading>
                  <div className="w-full flex flex-row justify-between items-center px-3 gap-6">
                    <Image
                      className="w-[60px] h-[64px]"
                      src={process.env.ASSETS_PATH + item.image}
                      alt=""
                    />

                    <div className="flex justify-start items-center w-full text-functional-900 text-lg mobile:text-sm pr-3">
                      <Text>{item.description}</Text>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <Link
            to={localeRoutes({ page: "schedule" })[i18n.language]}
            className="w-full flex justify-center items-center z-10 desktop:hidden"
          >
            <Button
              colorScheme="secondary"
              size="lg"
              className="z-30 desktop:mt-0 mt-4 mobile:px-0"
            >
              Agende uma conversa
            </Button>
          </Link>
        </div>

        <div className="bg-primary-600 animate-boxRandom1 rounded-xl absolute left-[10%] top-[10%] tablet:top-[5%] w-[190px] tablet:w-[120px] h-[190px] tablet:h-[120px] opacity-10" />
        <div className="bg-primary-600 animate-boxRandom2 rounded-xl absolute left-[8%] top-[8%] tablet:top-[10%] w-[80px] tablet:w-[80px] h-[80px] tablet:h-[80px] opacity-10" />

        <div className="bg-primary-600 animate-boxRandom1 rounded-xl absolute left-[-5%] bottom-[12%] tablet:bottom-[10%] w-[300px] tablet:w-[300px] h-[300px] tablet:h-[300px] opacity-10" />

        <div className="bg-primary-600 animate-boxRandom2 rounded-2xl absolute right-[30px] bottom-[15%] w-[200px] h-[200px] opacity-10" />
        <div className="bg-primary-600 animate-boxRandom3 rounded-2xl absolute right-[60px] top-[320px] w-[80px] h-[80px] opacity-10" />
      </div>
    </div>
  );
};
