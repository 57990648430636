import React, { useRef, useState } from "react";
import { useAppSelector } from "../../../store";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Heading,
  IconButton,
  Image,
  Spinner,
  Tag,
  Text,
  useIsVisible,
} from "linktou.ds.components";
import { Post } from "../../../types/blogTypes";
import { convertDateToBrazilianFormat } from "../../../utils/utils";
import { ArrowUpIcon, MagnifyIcon } from "linktou.ds.icons";
import { Input } from "linktou.ds.components";
import { Select } from "linktou.ds.components";
import CustomSelect from "./CustomSelect";
import { CookieService } from "../../../utils";
import axios from "axios";
import { localeRoutes } from "../../../locale/localeRoutes";
import { twMerge } from "tailwind-merge";

export const Feed = () => {
  const { t, i18n } = useTranslation("blog");

  const ref = useRef(null);
  const isVisible = useIsVisible(ref);

  const [searchParams, setSearchParams] = useSearchParams();
  let category = searchParams.get("category");
  const [filterString, setFilterString] = useState("");
  const [orderPostsBy, setOrderPostsBy] = useState(0);
  const startingPostQuantityToShowOnFeed = 6;
  const [postQuantityToShowOnFeed, setPostQuantityToShowOnFeed] = useState(
    startingPostQuantityToShowOnFeed,
  );

  const newsletterFakePostPosition = 6;

  const { blogPostsFetcher, blogCategoriesFetcher, blogMediaFetcher } = useAppSelector((store) => ({
    blogPostsFetcher: store.blogPostsFetcher,
    blogCategoriesFetcher: store.blogCategoriesFetcher,
    blogMediaFetcher: store.blogMediaFetcher,
  }));

  const totalCount = blogPostsFetcher.status === "fulfilled" ? blogPostsFetcher.data.totalCount : 0;

  const handleSelectChange = (e) => {
    if (e.value) {
      setSearchParams({ category: e.value });
    } else setSearchParams({});
    setPostQuantityToShowOnFeed(6);
  };

  const handleInputChange = (e) => {
    setFilterString(e.target.value);
  };

  const getPostCategoryArray = (categoriesArr: number[]) => {
    let arr = [];
    if (blogCategoriesFetcher.status === "fulfilled" && blogPostsFetcher.status === "fulfilled") {
      let categories = blogCategoriesFetcher.data;
      for (let c of categories) {
        for (let c2 of categoriesArr) {
          if (c.id === c2 && c.slug !== "uncategorized") {
            arr.push({ slug: c.slug, name: c.name });
          }
        }
      }
    }
    return arr;
  };

  const comparePostAndCategory = (post: Post, cat: string) => {
    return getPostCategoryArray(post.categories).find((c) => c.slug === cat);
  };

  const buildFeedPostsArr = (postsArr, imgsArr) => {
    let arr = [];
    for (let i = 0; i < postsArr.length; i++) {
      let post = postsArr[i];
      for (let img of imgsArr) {
        if (post.id === img.post && img.alt_text) {
          arr[i] = {
            img_url: img.media_details.sizes.medium
              ? img.media_details.sizes.medium.source_url
              : img.media_details.sizes.full.source_url,
            title: post.title.rendered,
            slug: post.slug,
            categories: post.categories,
            timeToRead:
              post.yoast_head_json.twitter_misc["Est. tempo de leitura"] ||
              post.yoast_head_json.twitter_misc["Est. reading time"],
            date: post.date,
          };
        }
      }
    }
    return arr.filter(Boolean);
  };

  const insertNewsletterElement = (arr: Post[]) => {
    let newArr = [...arr];
    let newsletterPlaceholder = {
      title: "NEWSLETTER",
      img_url: "",
      slug: "",
      categories: [],
      timeToRead: "",
      date: "",
      featured_media: null,
    };
    if (newArr.length < newsletterFakePostPosition) {
      newArr.push(newsletterPlaceholder);
    } else {
      newArr.splice(newsletterFakePostPosition - 1, 0, newsletterPlaceholder);
    }
    return newArr;
  };

  const createArrOfOrderedPosts = (arr: Post[]) => {
    const newArr = [...arr];
    if (orderPostsBy === 0) {
      return newArr;
    } else if (orderPostsBy === 1) {
      newArr.reverse();
    } else if (orderPostsBy === 2) {
      newArr.sort((a, b) => a.title.charCodeAt(0) - b.title.charCodeAt(0));
    } else if (orderPostsBy === 3) {
      newArr.sort((a, b) => b.title.charCodeAt(0) - a.title.charCodeAt(0));
    }
    return newArr;
  };

  const postsOrderSelectOptions = [
    {
      value: "0",
      label: t("feed.sort.options.most-recent"),
    },
    {
      value: "1",
      label: t("feed.sort.options.oldest"),
    },
    {
      value: "2",
      label: "A-Z",
    },
    {
      value: "3",
      label: "Z-A",
    },
  ];

  const populateCategoriesFilterOptions = (categories) => {
    let catArr = [
      {
        label: t("feed.select.defaultValue"),
        value: "",
      },
    ];

    for (let cat of categories) {
      if (cat.slug !== "uncategorized") {
        catArr.push({
          label: cat.name,
          value: cat.slug,
        });
      }
    }

    return catArr;
  };

  const [submissionForm, setSubmissionForm] = useState<{
    stage: number;
    name: string;
    email: string;
    consent: boolean;
  }>({
    stage: 1,
    name: "",
    email: "",
    consent: false,
  });

  const handleNewsletterCardClick = (stage) => {
    if (stage === 1) {
      setSubmissionForm({
        stage: 2,
        name: submissionForm.name,
        email: submissionForm.email,
        consent: submissionForm.consent,
      });
    }

    if (stage === 2) {
      setSubmissionForm({ stage: 3, name: "", email: "", consent: false });
      axios({
        method: "get",
        url: "https://api.ipify.org?format=json",
      })
        .then((res) => {
          if (res.status === 200)
            axios({
              method: "post",
              url: "https://api.hsforms.com/submissions/v3/integration/submit/23472751/1681d95a-c6d5-4401-ac3c-d213a013320d",
              data: {
                fields: [
                  {
                    name: "email",
                    value: submissionForm.email,
                  },
                  {
                    name: "firstname",
                    value: submissionForm.name,
                  },
                ],
                context: {
                  pageUri: "https://linktou.com.br/blog",
                  pageName: "Blog Feed - Newsletter",
                  hutk: CookieService.getCookie("hubspotutk"),
                  ipAddress: res.data.ip,
                },
                legalConsentOptions: {
                  consent: {
                    consentToProcess: true,
                    text: "Ao clicar em Assinar agora, você concorda em permitir que a LinkToU armazene e processe as informações pessoais enviadas acima para fornecer o conteúdo solicitado.",
                  },
                },
              },
            })
              .then((res) => {
                setSubmissionForm({
                  stage: 4,
                  name: "",
                  email: "",
                  consent: false,
                });
                return res;
              })
              .catch((err) => {
                if (typeof document !== "undefined") document.location.reload();
              });
        })
        .catch((err) => {
          if (typeof document !== "undefined") document.location.reload();
        });
    }
  };

  return (
    <div
      id="BlogHomePostsFeed"
      className="flex flex-col justify-center items-center w-full px-[160px] tablet:px-[80px] mobile:px-[24px] pb-[80px] tablet:pb-[60px]"
    >
      <div className="flex tablet:flex-col justify-between gap-8 items-center w-full my-2">
        <Heading
          variant={"h2"}
          className="text-3xl mobile:text-[28px] tablet:w-full text-primary-400 font-semibold"
        >
          {t("feed.title")}
        </Heading>
        <div className="flex mobile:flex-col justify-center items-center gap-6 tablet:w-full desktop:max-w-[500px]">
          {blogCategoriesFetcher.status === "fulfilled" ? (
            <Select
              options={populateCategoriesFilterOptions(blogCategoriesFetcher.data)}
              defaultValue={category ? category : ""}
              className="w-[460px] mobile:w-full"
              onChange={handleSelectChange}
            />
          ) : null}
          <Input
            onChange={handleInputChange}
            value={filterString}
            isFull
            className="tablet:w-full"
            placeholder={t("feed.input.placeholder")}
            rightAddon={<MagnifyIcon size="sm" color="text-functional-400" />}
          />
        </div>
      </div>

      <div className="flex mobile:flex-col mobile:gap-2 mobile:py-2 justify-between items-center mb-10 mobile:mb-4 w-full">
        <Text className="text-left mobile:w-full">
          {filterString === "" ? (
            <Text className="">
              {t("feed.filters.showing")}{" "}
              <Text className="font-bold">
                {blogPostsFetcher.status === "fulfilled"
                  ? Math.min(blogPostsFetcher.data.data.length, postQuantityToShowOnFeed - 1) + " "
                  : "... "}
              </Text>
              {t("feed.filters.of")}{" "}
              <Text className="font-bold">
                {blogPostsFetcher.status === "fulfilled"
                  ? blogPostsFetcher.data.data.length
                  : "..."}{" "}
              </Text>
              posts
            </Text>
          ) : (
            t("feed.filters.result")
          )}
        </Text>
        <div className="flex justify-center mobile:justify-end items-center mobile:w-full">
          <Text>{t("feed.sort.text")}</Text>
          <CustomSelect
            options={postsOrderSelectOptions}
            // defaultValue={postsOrderSelectOptions[0]}
            onChange={(e) => setOrderPostsBy(Number(e.value))}
          />
        </div>
      </div>

      <div
        ref={ref}
        className={twMerge(
          "flex justify-between items-center w-full flex-wrap desktop:gap-y-8 tablet:gap-y-4 py-4 opacity-0",
          isVisible && "animate-slideUp-1 opacity-100",
        )}
      >
        {blogPostsFetcher.status === "fulfilled" && blogMediaFetcher.status === "fulfilled" ? (
          insertNewsletterElement(
            createArrOfOrderedPosts(
              buildFeedPostsArr(blogPostsFetcher.data.data, blogMediaFetcher.data),
            ),
          ).map((post, index) => {
            if (post.title === "NEWSLETTER")
              return (
                <div
                  className="flex justify-center items-center w-[30%] tablet:w-[45%] mobile:w-full h-[420px] tablet:h-[400px] mobile:h-[360px] bg-functional-200 rounded-lg shadow-200 my-4 p-3"
                  key={"newsletter-fake-post"}
                >
                  <Card className="flex flex-col justify-evenly items-center w-full h-full rounded-lg p-3">
                    <CardHeader className="flex justify-center items-center">
                      {submissionForm.stage !== 2 ? (
                        <Image
                          className="rounded-md"
                          src={process.env.ASSETS_PATH + "/imgs/megafoneProp.png"}
                          alt="Receber novidades - Newsletter"
                        />
                      ) : (
                        <Text className="text-primary-400 font-semibold">
                          {t("feed.newsletter.subscription")}
                        </Text>
                      )}
                    </CardHeader>

                    <CardBody className="flex flex-col justify-center items-center gap-3 w-full text-left text-sm">
                      {submissionForm.stage === 1 ? (
                        <>
                          <Text className="max-w-[80%] text-center text-xl">
                            {t("feed.newsletter.text")}
                          </Text>
                          <Input
                            value={submissionForm.name}
                            onChange={(e) =>
                              setSubmissionForm({
                                name: e.target.value,
                                email: submissionForm.email,
                                stage: submissionForm.stage,
                                consent: submissionForm.consent,
                              })
                            }
                            className="min-w-full w-full"
                            placeholder={t("feed.newsletter.input.placeholder")}
                          />
                          <Input
                            type="email"
                            value={submissionForm.email}
                            onChange={(e) =>
                              setSubmissionForm({
                                email: e.target.value,
                                name: submissionForm.name,
                                stage: submissionForm.stage,
                                consent: submissionForm.consent,
                              })
                            }
                            className="min-w-full w-full"
                            placeholder="E-mail"
                          />
                        </>
                      ) : submissionForm.stage === 2 ? (
                        <>
                          <Text className="text-functional-800">
                            {t("feed.newsletter.disclaimer")}
                          </Text>
                          <Text className="text-functional-800">{t("feed.newsletter.cancel")}</Text>
                          <Checkbox
                            className=""
                            checked={submissionForm.consent}
                            label={t("feed.newsletter.checkbox.label")}
                            onChange={(e) =>
                              setSubmissionForm({
                                email: submissionForm.email,
                                name: submissionForm.name,
                                stage: submissionForm.stage,
                                consent: e.target.checked,
                              })
                            }
                          />
                        </>
                      ) : (
                        <>
                          <Text className="text-xl font-semibold text-primary-400">
                            {t("feed.newsletter.subscripted.ready")}{" "}
                          </Text>
                          <Text>{t("feed.newsletter.subscripted.text")}</Text>
                        </>
                      )}
                    </CardBody>

                    <CardFooter className="flex justify-center items-center w-full">
                      {submissionForm.stage < 3 ? (
                        <Button
                          disabled={
                            submissionForm.stage > 2 ||
                            submissionForm.name === "" ||
                            !submissionForm.email.match(
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            ) ||
                            (submissionForm.stage === 2 && submissionForm.consent === false)
                          }
                          isFull
                          className="max-w-[380px]"
                          onClick={() => handleNewsletterCardClick(submissionForm.stage)}
                        >
                          {submissionForm.stage === 1
                            ? t("feed.newsletter.button.receive")
                            : submissionForm.stage === 2
                            ? t("feed.newsletter.button.subscribe")
                            : " "}
                        </Button>
                      ) : null}
                    </CardFooter>
                  </Card>
                </div>
              );

            if (
              (index < postQuantityToShowOnFeed &&
                filterString === "" &&
                (comparePostAndCategory(post, searchParams.get("category")) ||
                  !searchParams.has("category"))) ||
              (filterString !== "" && post.title.toLowerCase().includes(filterString.toLowerCase()))
            ) {
              let blogCategorySlug = blogCategoriesFetcher.data!?.filter(
                (cat) => cat.id === post!?.categories[0],
              )[0].slug;

              return (
                <Link
                  to={
                    localeRoutes({
                      page: "blogDetail",
                      slug: post.slug,
                      category: blogCategorySlug,
                    })[i18n.language]
                  }
                  className={twMerge(
                    "flex flex-col justify-center items-center gap-4 shadow-200 w-[30%] tablet:w-[45%] mobile:w-full h-[420px] tablet:h-[400px] mobile:h-[360px] hover:bg-functional-200 rounded-lg my-4",
                    "hover:-translate-y-2 transition-transform",
                  )}
                  key={post.slug + "-card"}
                >
                  <Card className={"relative w-full h-full p-3"}>
                    <CardHeader className="flex justify-center items-center h-[55%]">
                      <Image
                        src={post.img_url}
                        alt={post.title}
                        loading="lazy"
                        className="object-cover w-full h-full rounded-lg"
                      />
                      <div className="absolute top-[5%] left-[5%] flex justify-start items-center w-full h-fit gap-2 flex-wrap">
                        {getPostCategoryArray(post.categories).map((cat) => {
                          return (
                            <Tag
                              className="relative text-functional-700 bg-primary-100 w-fit h-6 rounded-md"
                              key={cat.name}
                            >
                              {comparePostAndCategory(post, cat.slug).name}
                            </Tag>
                          );
                        })}
                      </div>
                    </CardHeader>

                    <CardBody className="p-4">
                      <div className={twMerge("flex justify-center items-center w-full h-full")}>
                        <Text className="text-primary-400 font-semibold text-xl">{post.title}</Text>
                      </div>
                    </CardBody>

                    <CardFooter className="px-4 -mt-3">
                      <Text className="text-xs font-normal">
                        {convertDateToBrazilianFormat(post.date, i18n.language)} /{" "}
                        {post.timeToRead.substring(-1, 5) + "."}
                      </Text>
                    </CardFooter>
                  </Card>
                </Link>
              );
            }
            return null;
          })
        ) : (
          <div className="flex justify-center items-center p-20 w-full h-[800px]">
            <Spinner />
          </div>
        )}
      </div>

      <div className="relative flex mobile:flex-col mobile:gap-4 w-full justify-center items-center py-10">
        <Button
          variant="outlined"
          size="lg"
          onClick={() => setPostQuantityToShowOnFeed(postQuantityToShowOnFeed + 9)}
          disabled={postQuantityToShowOnFeed >= totalCount || filterString !== ""}
        >
          {t("feed.button")}
        </Button>
        <IconButton
          className="absolute mobile:-bottom-4 mobile:right-0 right-2"
          onClick={() =>
            typeof window !== "undefined"
              ? window.scrollTo({ top: 720, left: 0, behavior: "smooth" })
              : null
          }
          size="lg"
          disabled={postQuantityToShowOnFeed === startingPostQuantityToShowOnFeed}
          icon={<ArrowUpIcon color="text-functional-100" />}
        />
      </div>
    </div>
  );
};
