import { Heading, Text } from "linktou.ds.components";
import React from "react";

interface HeroProps {
  t: any;
}

export const Hero = ({t}: HeroProps) => {
  return (
    <div id="root" className="flex justify-center items-center w-full h-[340px] bg-primary-800">
      <div
        id="wrapper"
        className="relative flex justify-start items-center w-full h-[340px] full:max-w-[1600px] bg-primary-800 px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]"
      >
        <div className="flex flex-col justify-center items-start w-full gap-8 z-10">
          <Heading
            variant="h1"
            className="w-full text-left text-4xl font-bold text-secondary-400 mobile:text-[28px]"
          >
            BLOG
          </Heading>
          <Text className="w-full text-left font-semibold text-white max-w-[560px] tablet:max-w-[460px] mobile:max-w-[360px]">
            {t("hero.title")}
          </Text>
        </div>

        <img
          className="tablet:hidden absolute right-[22.5%] tablet:right-[12%] mobile:right-[5%] bottom-0"
          src={process.env.ASSETS_PATH + "/imgs/genericHeroLine.png"}
          alt=""
        />
        <img
          className="desktop:hidden absolute right-[22.5%] tablet:right-[12%] mobile:right-[5%] bottom-0"
          src={process.env.ASSETS_PATH + "/imgs/genericHeroLineTablet.png"}
          alt=""
        />

        <img
          className="absolute tablet:scale-75 mobile:scale-50 right-[5%] tablet:right-[15%] mobile:right-[10%] top-[25%] "
          src={process.env.ASSETS_PATH + "/imgs/bracketsProp.png"}
          alt=""
        />
        <img
          className="absolute tablet:scale-75 right-[20%] tablet:right-[10%] bottom-[15%] tablet:bottom-[10%]"
          src={process.env.ASSETS_PATH + "/imgs/worldProp.png"}
          alt=""
        />

        <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute left-[2.5%] top-[20%] w-[80px] h-[80px] mobile:h-[40px] mobile:w-[40px] opacity-10 overflow-hidden" />
        <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute left-[5%] top-[25%] w-[120px] h-[120px] mobile:h-[80px] mobile:w-[80px] opacity-10 overflow-hidden" />

        <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute right-[5%] bottom-[27.5%] mobile:bottom-[45%] w-[160px] h-[160px] mobile:h-[100px] mobile:w-[100px] opacity-10 overflow-hidden" />
        <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute right-[12.5%] mobile:right-[3%] bottom-[5%] w-[200px] h-[200px] mobile:h-[160px] mobile:w-[160px] opacity-10 overflow-hidden" />
      </div>
    </div>
  );
};
