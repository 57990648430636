import { configureStore } from '@reduxjs/toolkit';

/** ROOT REDUCER */
import rootReducer from './rootReducer';
/** END ROOT REDUCER */

var store = configureStore({
  reducer: rootReducer,
  middleware: function middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false
    });
  }
});
export default store;