import React from "react";
import { Heading, Text } from "linktou.ds.components";
import { useTranslation } from "react-i18next";

export const Hero = () => {

  const {t} = useTranslation('schedule')

  return (
    <div className="flex justify-center items-center w-full h-[340px] bg-functional-200 text-white">
      <div className="relative flex justify-start items-center h-[340px] w-full full:max-w-[1600px] px-[160px] gap-16 tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
        <div className="flex flex-col justify-center items-start w-full z-40 gap-8 tablet:gap-6 mobile:gap-4">
          <Heading
            variant="h1"
            className="w-full text-left whitespace-nowrap text-4xl font-bold text-primary-400 mobile:text-[28px]"
          >
            {t('hero.title')}
          </Heading>
          <Text className="w-full max-w-[543px] text-left text-lg font-semibold text-functional-600 mobile:text-base">
            {t('hero.description')}
          </Text>
        </div>

        <img
          className="absolute right-[22.5%] tablet:right-[12%] mobile:right-[5%] bottom-0"
          src={process.env.ASSETS_PATH + "/imgs/genericAltHeroLine.png"}
          alt=""
        />

        <img
          className="absolute tablet:scale-75 mobile:scale-50 right-[5%] tablet:right-[15%] mobile:right-[10%] top-[25%] z-10"
          src={process.env.ASSETS_PATH + "/imgs/bracketsAltProp.png"}
          alt=""
        />
        <img
          className="absolute tablet:scale-75 right-[20%] tablet:right-[10%] bottom-[15%] tablet:bottom-[10%] z-10"
          src={process.env.ASSETS_PATH + "/imgs/worldAltProp.png"}
          alt=""
        />

        <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute left-[2.5%] top-[23%] w-[80px] h-[80px] mobile:h-[40px] mobile:w-[40px] opacity-10 overflow-hidden" />
        <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute left-[5%] top-[27%] w-[120px] h-[120px] mobile:h-[80px] mobile:w-[80px] opacity-10 overflow-hidden" />

        <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute right-[5%] bottom-[27.5%] mobile:bottom-[45%] w-[160px] h-[160px] mobile:h-[100px] mobile:w-[100px] opacity-10 overflow-hidden" />
        <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute right-[12.5%] mobile:right-[3%] bottom-[5%] w-[200px] h-[200px] mobile:h-[160px] mobile:w-[160px] opacity-10 overflow-hidden" />
      </div>
    </div>
  );
};
