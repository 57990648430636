import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button, Heading, Image } from "linktou.ds.components";
import { localeRoutes } from "../locale/localeRoutes";

export const Connectors = () => {
  const { t, i18n } = useTranslation("connectors");

  const connectors = [
    {
      img: "amazon.png",
      name: "Amazon",
    },
    {
      img: "dhl.png",
      name: "DHL",
    },

    {
      img: "erpflex.png",
      name: "ERP Flex",
    },
    {
      img: "totvs.png",
      name: "TOTVS",
    },
    {
      img: "tray.png",
      name: "Tray Commerce",
    },
    {
      img: "trello.png",
      name: "Trello",
    },
    {
      img: "powerbi.png",
      name: "Power BI",
    },
    {
      img: "rdstation.png",
      name: "RD Station",
    },
    {
      img: "salesforce.png",
      name: "Salesforce",
    },
    {
      img: "sap.png",
      name: "SAP",
    },
    {
      img: "vindi.png",
      name: "Vindi",
    },
    {
      img: "vtex.png",
      name: "VTEX",
    },
    {
      img: "vipcommerce.png",
      name: "VIP Commerce",
    },
    {
      img: "zendesk.png",
      name: "Zendesk",
    },
    {
      img: "zenvia.png",
      name: "Zenvia",
    },
    {
      img: "fedex.png",
      name: "Fedex",
    },
    {
      img: "gmail.png",
      name: "Gmail",
    },
    {
      img: "googleagenda.png",
      name: "Google Agenda",
    },
    {
      img: "hubspot.png",
      name: "Hubspot",
    },
    {
      img: "infracommerce.png",
      name: "Infracommerce",
    },
    {
      img: "jira.png",
      name: "Jira",
    },
    {
      img: "linx.png",
      name: "Linx",
    },
    {
      img: "loggi.png",
      name: "Loggi",
    },
    {
      img: "lojaintegrada.png",
      name: "Loja Integrada",
    },
    {
      img: "magento.png",
      name: "Magento",
    },
    {
      img: "hi.png",
      name: "Hi Platform",
    },
    {
      img: "mercadolivre.png",
      name: "Mercado Livre",
    },
    {
      img: "monday.png",
      name: "Monday",
    },
    {
      img: "movidesk.png",
      name: "Movidesk",
    },
    {
      img: "netsuite.png",
      name: "Netsuite",
    },
    {
      img: "whatsapp.png",
      name: "Whatsapp",
    },
    {
      img: "outlookagenda.png",
      name: "Outlook",
    },
    {
      img: "pipedrive.png",
      name: "Pipedrive",
    },
    {
      img: "shopify.png",
      name: "Shopify",
    },
    {
      img: "tableau.png",
      name: "Tableau",
    },
    {
      img: "correios.png",
      name: "Correios",
    },
    {
      img: "cybersource.png",
      name: "Cybersource",
    },
    {
      img: "datastudio.png",
      name: "Data Studio",
    },
    {
      img: "dynamics.png",
      name: "Microsoft Dynamics",
    },
    {
      img: "zoho.png",
      name: "Zoho",
    },
    {
      img: "serasa.png",
      name: "Serasa",
    },
    {
      img: "americanas.png",
      name: "Lojas Americanas",
    },
    {
      img: "b2w.png",
      name: "B2W",
    },
    {
      img: "clearsale.png",
      name: "ClearSale",
    },
    {
      img: "magalu.png",
      name: "Magalu",
    },
    {
      img: "contaazul.png",
      name: "Conta Azul",
    },
    {
      img: "octadesk.png",
      name: "Octadesk",
    },
    {
      img: "protheus.png",
      name: "Protheus",
    },
  ];

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }
  const Node = ({ children, direction }) => (
    <div
      className={`
                    ${
                      direction === "right"
                        ? `animate-travelUp tablet:animate-travelRight`
                        : `animate-travelDown tablet:animate-travelLeft`
                    } 
                    flex flex-col justify-evenly items-center 
                    min-w-[88px] min-h-[88px]
                    tablet:min-w-[60px] tablet:min-h-[60px] 
                    border-2 border-functional-400 bg-functional-100
                    shadow-200
                    rounded-lg
                    `}
    >
      {children}
    </div>
  );

  return (
    <div className=" flex flex-col justify-evenly items-center w-full min-h-[800px] bg-functional-100 px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
      <div
        className={`relative flex tablet:flex-col justify-center items-center desktop:h-[600px] w-full bg-primary-100 rounded-lg shadow-300 z-10 text-center overflow-hidden py-12 mobile:py-6 max-w-7xl`}
      >
        <div className="flex flex-col justify-center items-start w-1/2 tablet:w-full text-left tablet:text-center desktop:pl-[7.5%]">
          <Heading
            variant={"h2"}
            className="w-full desktop:text-4xl desktop:font-bold tablet:text-[28px] tablet:font-semibold mobile:text-[28px] mobile:font-semibold text-primary-400"
          >
            {t("title")}
          </Heading>
          <Heading
            variant={"h3"}
            className="desktop:w-[90%] w-full tablet:px-11 mobile:px-2 mobile:text-base font-semibold py-8 tablet:py-6 desktop:pr-10"
          >
            {t("subtitle")}
          </Heading>
          <Link
            to={localeRoutes({ page: "schedule" })[i18n.language]}
            className="tablet:hidden"
          >
            <Button
              colorScheme="primary"
              size="lg"
              className="mobile:mb-10 z-20"
            >
              {t("cta")}
            </Button>
          </Link>
        </div>

        <div className="relative flex tablet:flex-col justify-center full:w-[40%] w-1/2 tablet:w-full items-center desktop:pr-[5%]">
          <div className="flex desktop:flex-col w-full justify-evenly items-center h-1/2 tablet:h-1/3 overflow-hidden gap-8 tablet:gap-4 py-4 tablet:py-2">
            {shuffle(connectors.slice(0, 12)).map((connector, index) => {
              return (
                <Node direction={"left"} key={connector.name + index}>
                  <Image
                    className={"w-[62px] tablet:w-[48px]"}
                    src={process.env.ASSETS_PATH + "/nodes/" + connector.img}
                    alt=""
                  />
                  {/* <Text className="text-sm -mt-3 leading-tight">{connector.name}</Text> */}
                </Node>
              );
            })}
          </div>
          <div className="flex desktop:flex-col w-full justify-evenly items-center h-1/2 tablet:h-1/3 overflow-hidden gap-8 tablet:gap-4 py-4 tablet:py-2">
            {shuffle(connectors.slice(12, 24)).map((connector, index) => {
              return (
                <Node direction={"right"} key={connector.name + index}>
                  <Image
                    className={"w-[62px] tablet:w-[48px]"}
                    src={process.env.ASSETS_PATH + "/nodes/" + connector.img}
                    alt=""
                  />
                  {/* <Text className="text-sm -mt-3 leading-tight">{connector.name}</Text> */}
                </Node>
              );
            })}
          </div>
          <div className="flex desktop:flex-col w-full justify-evenly items-center h-1/2 tablet:h-1/3 overflow-hidden gap-8 tablet:gap-4 py-4 tablet:py-2">
            {shuffle(connectors.slice(24, 36)).map((connector, index) => {
              return (
                <Node direction={"left"} key={connector.name + index}>
                  <Image
                    className={"w-[62px] tablet:w-[48px]"}
                    src={process.env.ASSETS_PATH + "/nodes/" + connector.img}
                    alt=""
                  />
                  {/* <Text className="text-sm -mt-3 leading-tight">{connector.name}</Text> */}
                </Node>
              );
            })}
          </div>
          <div className="flex desktop:flex-col w-full justify-evenly items-center h-1/2 tablet:h-1/3 overflow-hidden gap-8 tablet:gap-4 py-4 tablet:py-2">
            {shuffle(connectors.slice(36, 48))
              .reverse()
              .map((connector, index) => {
                return (
                  <Node direction={"right"} key={connector.name + index}>
                    <Image
                      className={"w-[62px] tablet:w-[48px]"}
                      src={process.env.ASSETS_PATH + "/nodes/" + connector.img}
                      alt=""
                    />
                    {/* <Text className="text-sm -mt-3 leading-tight">{connector.name}</Text> */}
                  </Node>
                );
              })}
          </div>
        </div>

        <Link
          to={localeRoutes({ page: "integrator" })[i18n.language]}
          className="flex justify-center items-center desktop:hidden w-full mb-10 mobile:px-[24px]"
        >
          <Button
            colorScheme="primary"
            size="lg"
            className="mobile:mb-10 mt-10 z-20"
          >
            {t("cta")}
          </Button>
        </Link>
      </div>
    </div>
  );
};
