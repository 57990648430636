import blogPostsFetcherReducer from "../pages/Blog/slices/blogPostsFetcherSlice";
import blogCategoriesFetcherReducer from "../pages/Blog/slices/blogCategoriesFetcherSlice";
import blogPostDetailFetcherReducer from "../pages/Blog/slices/blogPostDetailFetcherSlice";
import blogLatestPostsFetcherReducer from "../pages/Blog/slices/blogLatestPostsFetcherSlice";
import blogMediaFetcherReducer from "../pages/Blog/slices/blogMediaFetcherSlice";
import blogMediaDetailFetcherReducer from "../pages/Blog/slices/blogMediaDetailFetcherSlice";
import pageFetcherSliceReducer from "../pages/pageFetcherSlice";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  blogPostsFetcher: blogPostsFetcherReducer,
  blogCategoriesFetcher: blogCategoriesFetcherReducer,
  blogPostDetailFetcher: blogPostDetailFetcherReducer,
  blogLatestPostsFetcher: blogLatestPostsFetcherReducer,
  blogMediaFetcher: blogMediaFetcherReducer,
  blogMediaDetailFetcher: blogMediaDetailFetcherReducer,
  pageFetcher: pageFetcherSliceReducer
};