/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { RouteMatch, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { clearBlogPostDetailFetcher, fetchPost } from "../slices/blogPostDetailFetcherSlice";
import { clearBlogMediaDetailFetcher, fetchMedia } from "../slices/blogMediaDetailFetcherSlice";
import {
  clearBlogCategoriesFetcher,
  fetchAllCategories,
} from "../slices/blogCategoriesFetcherSlice";

import { Footer, Header } from "../../../layout";
import { DetailContent, Hero, LatestPosts } from "../components";
import { Helmet } from "react-helmet";
import { Banner } from "../../../common/Banner";
import { localeRoutes } from "../../../locale/localeRoutes";
import WhatsAppButton from "../../../common/WhatsAppButton";

const BlogDetailPage = () => {
  const { slug } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t, i18n } = useTranslation("blog");
  const [postCategory, setPostCategory] = useState("");

  const { blogPostDetailFetcher, blogMediaDetailFetcher, blogCategoriesFetcher } = useAppSelector(
    (store) => ({
      blogPostDetailFetcher: store.blogPostDetailFetcher,
      blogMediaDetailFetcher: store.blogMediaDetailFetcher,
      blogCategoriesFetcher: store.blogCategoriesFetcher,
    }),
  );

  useEffect(() => {
    if (slug) dispatch(fetchPost(slug));
    dispatch(fetchAllCategories());

    return () => {
      dispatch(clearBlogPostDetailFetcher());
      dispatch(clearBlogCategoriesFetcher());
      dispatch(clearBlogMediaDetailFetcher());
    };
  }, []);

  useEffect(() => {
    if (
      blogPostDetailFetcher.status === "fulfilled" &&
      blogCategoriesFetcher.status === "fulfilled" &&
      blogCategoriesFetcher.data.length
    ) {
      dispatch(fetchMedia(blogPostDetailFetcher.data.featured_media));
      setPostCategory(getPostCategoryArray(blogPostDetailFetcher.data.categories)[0].slug);
    }
  }, [blogPostDetailFetcher.status, blogCategoriesFetcher.status]);

  const getPostCategoryArray = (categoriesArr: number[]): { name: string; slug: string }[] => {
    let arr: { name: string; slug: string }[] = [];
    if (
      blogCategoriesFetcher.status === "fulfilled" &&
      blogPostDetailFetcher.status === "fulfilled"
    ) {
      let categories = blogCategoriesFetcher.data;
      for (let c of categories) {
        for (let c2 of categoriesArr) {
          if (c.id === c2 && c.slug !== "uncategorized") {
            arr.push({ slug: c.slug, name: c.name });
          }
        }
      }
    }

    return arr;
  };

  const image_url =
    blogMediaDetailFetcher.status === "fulfilled" ? blogMediaDetailFetcher.data.source_url : null;
  const category =
    blogCategoriesFetcher.status === "fulfilled" &&
    blogPostDetailFetcher.status === "fulfilled" &&
    blogPostDetailFetcher.error !== 404
      ? getPostCategoryArray(blogPostDetailFetcher.data.categories)
      : [{ name: "Automação", slug: "automacao" }];

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{blogPostDetailFetcher?.data?.yoast_head_json?.title}</title>
        <meta
          name="description"
          content={blogPostDetailFetcher?.data?.yoast_head_json?.description}
        />
        <link href={"https://linktou.com.br/blog/" + slug} rel="canonical"></link>
        {blogPostDetailFetcher.status === "fulfilled" && blogPostDetailFetcher.error !== 404 ? (
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              headline: blogPostDetailFetcher?.data.title?.rendered,
              alternativeHeadline: blogPostDetailFetcher?.data.title?.rendered,
              image: image_url,
              editor: "LinKToU",
              genre: category[0].name,
              keywords: t("blog.keywords", { ns: "meta" }),
              publisher: "LinKToU",
              url: `https://linktou.com.br/blog/${slug}`,
              datePublished: blogPostDetailFetcher?.data.date,
              dateCreated: blogPostDetailFetcher?.data.date,
              dateModified: blogPostDetailFetcher?.data.modified,
              description: blogPostDetailFetcher?.data.yoast_head_json?.description,
              articleBody: blogPostDetailFetcher?.data.content?.rendered,
              author: {
                "@type": "Organization",
                name: "LinkToU",
              },
            })}
          </script>
        ) : null}
      </Helmet>
      <WhatsAppButton />
      <Header routes={localeRoutes({ page: "blogDetail", slug: slug, category: postCategory })} />
      <Hero t={t} />
      <DetailContent />
      <LatestPosts t={t} i18n={i18n} />
      <Banner />
      <Footer />
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  element: <BlogDetailPage />,
  validateRoute: async (
    dispatch,
    page: RouteMatch<string>,
    store,
    path: string,
    matchPath,
    languages: string[],
  ) => {
    return new Promise(async (resolve, reject) => {
      let lang = path.split("/")[1];
      let pathHasLang = languages.includes(lang);
      let index = pathHasLang ? 4 : 3;
      let postPath = path.split("/")[index];

      const res = await dispatch(fetchPost(postPath));
      await dispatch(fetchAllCategories());
      if (res.payload !== 404) {
        resolve({ isValid: true });
      } else resolve({ isValid: false });
    });
  },
};
