import React, { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";

import "./locale/config";

import { Routes } from "../Routes";
import { useTranslation } from "react-i18next";

interface AppProps {
  lang?: string;
}

const App = ({ lang }: AppProps) => {
  const elements = useRoutes(Routes);
  const location = useLocation();

  const { i18n } = useTranslation();

  if (lang) i18n.changeLanguage(lang);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{elements}</>;
};

export default App;
