import React, { useRef } from "react";
import {
  Button,
  Heading,
  Image,
  Text,
  useIsVisible,
} from "linktou.ds.components";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { localeRoutes } from "../../../locale/localeRoutes";
import { Link } from "react-router-dom";

export const Article = () => {
  const { t, i18n } = useTranslation("integrator");
  const ref = useRef();
  const isVisible = useIsVisible(ref, true);

  return (
    <div className="w-full h-fit flex flex-col justify-center items-center px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
      <div
        ref={ref}
        className="w-full h-fit flex flex-col justify-center items-center full:max-w-7xl full:gap-20"
      >
        <div
          className={twMerge(
            "flex flex-col justify-center items-center w-full gap-4 opacity-0",
            isVisible && "animate-slideUp opacity-100"
          )}
        >
          <Heading
            variant="h2"
            className="text-4xl tablet:text-[28px] mobile:text-[24px] font-bold text-primary-400"
          >
            {t("article.title")}
          </Heading>
          <Text
            className={
              "text-xl tablet:max-w-[500px] font-semibold text-center mobile:text-left"
            }
          >
            {t("article.subtitle")}
          </Text>
        </div>
        <div className="flex tablet:flex-col-reverse justify-between items-center min-h-[400px] gap-16 tablet:py-8 tablet:gap-12">
          <div
            className={twMerge(
              "flex justify-center items-center w-1/2 tablet:w-full opacity-0",
              isVisible && "animate-slideUp-2 opacity-100"
            )}
          >
            <Image
              className=""
              src={process.env.ASSETS_PATH + "/imgs/abbandonedCartPreview.png"}
              alt=""
            />
          </div>
          <div
            className={twMerge(
              "flex flex-col justify-center items-start w-1/2 text-left gap-3 mobile:px-0 tablet:w-full opacity-0",
              isVisible && "animate-slideUp-3 opacity-100"
            )}
          >
            <Heading
              variant="h2"
              className="w-full font-semibold text-[28px] tablet:text-2xl text-primary-400 mb-4"
            >
              {t("article.articles.1.title")}
            </Heading>
            <Text className="">{t("article.articles.1.description1")}</Text>
            <Text className="">{t("article.articles.1.description2")}</Text>
          </div>
        </div>
        <div className="flex tablet:flex-col justify-between items-center min-h-[400px] gap-16 tablet:py-8 tablet:gap-12">
          <div
            className={twMerge(
              "flex flex-col justify-center items-start w-1/2 text-left gap-3 mobile:px-0 tablet:w-full opacity-0",
              isVisible && "animate-slideUp-4 opacity-100"
            )}
          >
            <Heading
              variant="h2"
              className="w-full font-semibold text-[28px] tablet:text-2xl text-primary-400 mb-4"
            >
              {t("article.articles.2.title")}
            </Heading>
            <Text className="">{t("article.articles.2.description1")}</Text>
            <Text className="">{t("article.articles.2.description2")}</Text>
          </div>
          <div
            className={twMerge(
              "flex justify-center items-center w-1/2 tablet:w-full opacity-0",
              isVisible && "animate-slideUp-4 opacity-100"
            )}
          >
            <Image
              className=""
              src={process.env.ASSETS_PATH + "/imgs/flowList.png"}
              alt=""
            />
          </div>
        </div>
        <div className="flex justify-center items-center w-full pb-20 mt-2">
          <Link
            to={localeRoutes({ page: "schedule" })[i18n.language]}
            className="flex justify-center items-center w-full z-10 mobile:w-full"
          >
            <Button size="lg">{t("article.cta")}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
