import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button, Heading } from "linktou.ds.components";
import { localeRoutes } from "../locale/localeRoutes";

export const Banner = () => {
  const { t, i18n } = useTranslation("banner");

  return (
    <div className="relative flex justify-center items-center w-full max-h-[620px] bg-primary-800 text-white px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
      <div className="flex justify-center items-center w-full max-w-7xl">
        <img
          className="absolute tablet:scale-75 mobile:scale-50 right-[12.5%] top-[25%] tablet:top-[10%]"
          src={process.env.ASSETS_PATH + "/imgs/worldProp.png"}
          alt=""
        />
        <img
          className="absolute tablet:scale-75 mobile:scale-50 left-[12.5%] tablet:left-[3%] bottom-[35%] tablet:bottom-[10%] mobile:bottom-[5%]"
          src={process.env.ASSETS_PATH + "/imgs/plugProp.png"}
          alt=""
        />
        <img
          className="absolute tablet:scale-75 mobile:scale-50 right-[12.5%] tablet:right-[3%] bottom-[20%]"
          src={process.env.ASSETS_PATH + "/imgs/bracketsProp.png"}
          alt=""
        />

        <div className="flex flex-col justify-center items-center tablet:w-full text-center gap-9 tablet:gap-4 z-10">
          <Heading variant="h4" className="text-[28px] mobile:text-2xl font-semibold">
            Integrator
          </Heading>
          <Heading
            className="text-4xl tablet:text-[28px] font-semibold text-secondary-400"
            variant={"h3"}
          >
            {t("title")}
          </Heading>
          <Heading
            variant="h3"
            className="text-lg tablet:text-md font-semibold max-w-[60%] tablet:max-w-[90%]"
          >
            {t("subtitle")}
          </Heading>
          <Link
            to={localeRoutes({page: "schedule"})[i18n.language]}
            className="flex justify-center items-center w-full mt-6"
          >
            <Button colorScheme="secondary" size="lg">
              {t("cta")}
            </Button>
          </Link>
        </div>

        <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute left-[15%] top-[65%] tablet:top-[8%] w-[120px] h-[120px] opacity-10 overflow-hidden" />
        <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute left-[10%] top-[42%] tablet:top-[5%] w-[190px] tablet:w-[80px] h-[190px] tablet:h-[80px] opacity-10 overflow-hidden" />

        <div className="bg-primary-600 animate-boxRandom2 rounded-lg absolute right-[20%] tablet:right-[-10%] bottom-[60%] w-[140px] h-[140px] opacity-10 tablet:hidden" />
        <div className="bg-primary-600 animate-boxRandom3 rounded-xl absolute right-[7.5%] tablet:left-[-30%] bottom-[5%] w-[300px] h-[300px] opacity-10 overflow-hidden" />
      </div>
    </div>
  );
};
