/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RouteMatch, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getCategoryNumberFromSlug } from "../../../utils/utils";

import { Footer, Header } from "../../../layout";
import { Feed, Hero, MainPost } from "../components";
import { Helmet } from "react-helmet";

import { clearBlogPostsFetcher, fetchPostsByCategory } from "../slices/blogPostsFetcherSlice";
import {
  clearBlogCategoriesFetcher,
  fetchAllCategories,
} from "../slices/blogCategoriesFetcherSlice";
import { clearBlogMediaFetcher, fetchAllMedia } from "../slices/blogMediaFetcherSlice";
import { clearBlogPostDetailFetcher, fetchPost } from "../slices/blogPostDetailFetcherSlice";
import { clearBlogMediaDetailFetcher, fetchMedia } from "../slices/blogMediaDetailFetcherSlice";
import { clearPageFetcher, fetchBlogHome } from "../../pageFetcherSlice";
import { localeRoutes } from "../../../locale/localeRoutes";
import WhatsAppButton from "../../../common/WhatsAppButton";

const BlogHomePage = () => {
  const { t, i18n } = useTranslation("blog");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const paramCategory = searchParams.get("category");

  const dispatch = useAppDispatch();
  const {
    blogPostsFetcher,
    blogCategoriesFetcher,
    blogMediaFetcher,
    blogPostDetailFetcher,
    blogMediaDetailFetcher,
    pageFetcher,
  } = useAppSelector((store) => ({
    blogPostsFetcher: store.blogPostsFetcher,
    blogCategoriesFetcher: store.blogCategoriesFetcher,
    blogMediaFetcher: store.blogMediaFetcher,
    blogPostDetailFetcher: store.blogPostDetailFetcher,
    blogMediaDetailFetcher: store.blogMediaDetailFetcher,
    pageFetcher: store.pageFetcher,
  }));

  useEffect(() => {
    if (blogCategoriesFetcher.status === "idle") dispatch(fetchAllCategories());
    return () => {
      if (blogCategoriesFetcher.status === "fulfilled") dispatch(clearBlogCategoriesFetcher());
    };
  }, [blogCategoriesFetcher.status]);

  useEffect(() => {
    if (blogCategoriesFetcher.status === "fulfilled" && blogPostsFetcher.status === "idle") {
      let term = searchParams.get("category")
        ? getCategoryNumberFromSlug(searchParams.get("category"), blogCategoriesFetcher.data)
        : undefined;
      dispatch(fetchPostsByCategory(term));
    }
    return () => {
      if (blogPostsFetcher.status === "fulfilled") dispatch(clearBlogPostsFetcher());
    };
  }, [blogPostsFetcher.status, blogCategoriesFetcher.status, searchParams]);

  useEffect(() => {
    if (blogMediaFetcher.status === "idle") dispatch(fetchAllMedia());
    return () => {
      if (blogMediaFetcher.status === "fulfilled") dispatch(clearBlogMediaFetcher());
    };
  }, [blogMediaFetcher.status]);

  useEffect(() => {
    if (blogPostDetailFetcher.status === "idle")
      dispatch(fetchPost("transforme-gestao-empresarial-com-automacao-erp"));
    return () => {
      if (blogPostDetailFetcher.status === "fulfilled") dispatch(clearBlogPostDetailFetcher());
    };
  }, [blogPostDetailFetcher.status]);

  useEffect(() => {
    if (blogMediaDetailFetcher.status === "idle") dispatch(fetchMedia("1386"));
    return () => {
      if (blogMediaDetailFetcher.status === "fulfilled") dispatch(clearBlogMediaDetailFetcher());
    };
  }, [blogMediaDetailFetcher.status]);

  useEffect(() => {
    if (pageFetcher.status === "idle") dispatch(fetchBlogHome());
    return () => {
      if (pageFetcher.status === "fulfilled") dispatch(clearPageFetcher());
    };
  }, [pageFetcher.status]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t("blog.title", { ns: "meta" })}</title>
        <meta name="description" content={t("blog.description", { ns: "meta" })} />
        <link href="https://linktou.com.br/blog" rel="canonical"></link>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "LinkToU",
            alternateName: t("blog.alternateName", { ns: "meta" }),
            url: `https://linktou.com.br/blog`,
            keywords:
              "Automação, Hiperautomação, Integração, iPaaS, Plataforma de Integração como Serviço, Empresa, Produtividade, Automação de processos, Automação robótica de processos (RPA), Automação de fluxo de trabalho, Automação de marketing, Automação industrial, Automação residencial, Integração de sistemas, Integração de aplicativos, Integração de dados, Plataforma de integração, Integração de nuvem, Plataforma de desenvolvimento de aplicativos (PDA), Plataforma de gerenciamento de processos de negócios (BPM), Automação de tarefas repetitivas, Automação de atendimento ao cliente, Automação de vendas, Automação de recursos humanos, Automação financeira, Automação logística, Otimização de processos, Eficiência operacional, Redução de custos, Análise de dados, Análise de desempenho, Análise de produtividade",
            logo: process.env.ASSETS_PATH + `/imgs/logo-l2u.svg`,
            about: t("blog.about", { ns: "meta" }),
            editor: "LinkToU",
            author: {
              "@type": "Organization",
              name: "LinkToU",
            },
          })}
        </script>
        {/* Hubspot analytics & tracking code */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/23472751.js"
        ></script>
      </Helmet>
      <Header
        routes={localeRoutes({
          page: paramCategory ? "blogCategory" : "blog",
          paramCategory: paramCategory,
        })}
      />
      <WhatsAppButton />
      <Hero t={t} />
      <MainPost t={t} i18n={i18n} />
      <Feed />
      <Footer />
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  element: <BlogHomePage />,
  validateRoute: async (
    dispatch,
    page: RouteMatch<string>,
    store,
    path: string,
    matchPath,
    languages: string[],
  ) => {
    let lang = path === "/blog" ? "br" : path.split("/")[1];
    const routeExists = localeRoutes({ page: "schedule" })[lang] !== undefined;

    return new Promise(async (resolve, reject) => {
      if (languages.includes(lang) && routeExists) {
        await Promise.all([
          dispatch(fetchPostsByCategory(undefined)),
          dispatch(fetchAllMedia()),
          dispatch(fetchAllCategories()),
          dispatch(fetchPost("transforme-gestao-empresarial-com-automacao-erp")),
          dispatch(fetchMedia("1386")),
          dispatch(fetchBlogHome()),
        ]);
        resolve({ isValid: true });
      } else resolve({ isValid: false });
    });
  },
};
