import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Frame = () => {
  const { i18n } = useTranslation();

  const Meeting = () => {
    return {
      br: (
        <div
          key="br-meeting"
          id="br-meeting"
          className="meetings-iframe-container w-full"
          data-src="https://meetings.hubspot.com/pedro-cotrim?embed=true"
        ></div>
      ),
      en: (
        <div
          key="en-meeting"
          id="en-meeting"
          className="meetings-iframe-container w-full"
          data-src="https://meetings.hubspot.com/pedro-cotrim/60-min-30-mine-15-min-meeting?embed=true"
        ></div>
      ),
      es: (
        <div
          key="es-meeting"
          id="es-meeting"
          className="meetings-iframe-container w-full"
          data-src="https://meetings.hubspot.com/pedro-cotrim/60-min-30-mine-15-min-meeting-clone?embed=true"
        ></div>
      ),
    }[i18n.language];
  };

  return (
    <div
      id="meeting-container"
      className="w-full h-fit min-h-[200px] px-[160px] gap-16 tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px] flex items-center justify-center bg-functional-100"
    >
      <script
        type="text/javascript"
        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        defer
      ></script>
      <Meeting />
    </div>
  );
};

export default Frame;
