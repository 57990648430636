import React from "react";
import { Article, Hero } from "./components";
import { Footer, Header } from "../../layout";
import { Cards } from "../../common/Cards";
import { Connectors } from "../../common/Connectors";
import { Banner } from "../../common/Banner";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { RouteMatch } from "react-router-dom";
import { localeRoutes } from "../../locale/localeRoutes";
import WhatsAppButton from "../../common/WhatsAppButton";

const IntegratorPage = () => {
  const { t, i18n } = useTranslation("meta");

  const cardsProps = {
    title: t("integrator.title", { ns: "cards" }),
    subtitle: t("integrator.subtitle", { ns: "cards" }),
    image1: "NOSSA1.png",
    text1: t("integrator.text1", { ns: "cards" }),
    image2: "NOSSA2.png",
    text2: t("integrator.text2", { ns: "cards" }),
    image3: "NOSSA3.png",
    text3: t("integrator.text3", { ns: "cards" }),
    buttonText: t("integrator.cta", { ns: "cards" }),
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        <title>{t("integrator.title")}</title>
        <meta name="description" content={t("integrator.description")} data-react-helmet="true" />
        <link href="https://linktou.com.br/integrator" rel="canonical"></link>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "LinkToU",
            alternateName: t("integrator.alternateName"),

            category: "Product",
            url: "https://linktou.com.br/integrator",
            logo: process.env.ASSETS_PATH + `/imgs/logo-l2u.svg`,
            brand: {
              "@type": "Thing",
              name: "LinkToU",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.8",
              reviewCount: "89",
            },
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+5511935853846",
              contactType: "customer service",
              areaServed: "BR",
              availableLanguage: ["Portuguese", "en"],
            },
            address: {
              "@type": "PostalAddress",
              streetAddress: "R. Dr. Geraldo Campos Moreira, 17 - Cidade Monções",
              addressLocality: "São Paulo",
              addressRegion: "SP",
              postalCode: "04571-020",
              addressCountry: "BRA",
            },
            sameAs: [
              "https://www.linkedin.com/company/linktou",
              "https://www.instagram.com/linktoubr/",
            ],
          })}
        </script>
      </Helmet>
      <WhatsAppButton />
      <Header altColors={true} routes={localeRoutes({ page: "integrator" })} />
      <Hero />
      <Cards props={cardsProps} />
      <Connectors />
      <Article />
      <Banner />
      <Footer />
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  element: <IntegratorPage />,
  validateRoute: async (
    dispatch,
    page: RouteMatch<string>,
    store,
    path: string,
    matchPath,
    languages: string[],
  ) => {
    // await dispatch(fetchHomePage());
    let lang = path === "/integrator" ? "br" : path.split("/")[1];
    const routeExists = localeRoutes({ page: "integrator" })[lang] !== undefined;

    return new Promise((resolve, reject) => {
      if (languages.includes(lang) && routeExists) resolve({ isValid: true });
      else resolve({ isValid: false });
    });
  },
};
