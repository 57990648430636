import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { localeRoutes } from "../locale/localeRoutes";
import { Button, Heading, Text, useIsVisible } from "linktou.ds.components";
import { twMerge } from "tailwind-merge";

export const Cards = ({ props }) => {
  const ref = useRef();
  const isVisible = useIsVisible(ref, true);

  const { t, i18n } = useTranslation();

  const {
    title,
    subtitle,
    image1,
    text1,
    image2,
    text2,
    image3,
    text3,
    buttonText,
  } = props;

  return (
    <div className="relative flex flex-col justify-between items-center w-full min-h-[400px] bg-functional-100 px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
      <div className="flex flex-col justify-between items-center w-full max-w-7xl">
        <img
          className={`absolute tablet:hidden ${
            subtitle ? "bottom-[37.5%]" : "bottom-[40%]"
          }`}
          src={process.env.ASSETS_PATH + "/imgs/homeCardsLine.png"}
          alt=""
        />

        <div
          className={twMerge(
            "flex flex-col justify-between items-center w-full opacity-0",
            isVisible && "animate-slideUp opacity-100"
          )}
        >
          <Heading
            variant="h2"
            className="text-4xl tablet:text-3xl mobile:text-2xl text-primary-400 font-bold text-center w-[80%]"
          >
            {title}
          </Heading>
          {subtitle ? (
            <Heading
              variant="h3"
              className="text-functional-800 font-semibold text-center w-[80%] my-4"
            >
              {subtitle}
            </Heading>
          ) : null}
        </div>

        <div
          ref={ref}
          className={twMerge(
            "flex tablet:flex-col justify-between gap-8 items-center h-2/4 w-full my-8 z-10 opacity-0",
            isVisible && "animate-slideUp-2 opacity-100"
          )}
        >
          <div
            className={
              "flex desktop:flex-col justify-between gap-10 mobile:gap-6 items-center desktop:w-[300px] w-full h-full"
            }
          >
            <div
              className={`flex justify-center items-center bg-primary-100 rounded-2xl shadow-200 tablet:rounded-lg desktop:w-[60%] w-[20%] min-w-[60px] h-full`}
            >
              <img
                className="min-w-[40px] min-h-[40px] w-[100%] h-[100%] p-10 tablet:p-4"
                src={process.env.ASSETS_PATH + "/imgs/" + image1}
                alt={text1}
              />
            </div>
            <Text className="max-w-[300px] tablet:max-w-[500px] w-full h-full font-semibold leading-6 text-functional-800">
              {text1}
            </Text>
          </div>

          <div className="flex desktop:flex-col justify-between gap-10 mobile:gap-6 items-center desktop:w-[300px] w-full h-full">
            <div
              className={`flex justify-center items-center bg-primary-100 rounded-2xl shadow-200 tablet:rounded-lg desktop:w-[60%] w-[20%] min-w-[60px] h-full`}
            >
              <img
                className="min-w-[40px] min-h-[40px] w-[100%] h-[100%] p-10 tablet:p-4"
                src={process.env.ASSETS_PATH + "/imgs/" + image2}
                alt={text2}
              />
            </div>
            <Text className="max-w-[300px] tablet:max-w-[500px] w-full h-full font-semibold leading-6 text-functional-800">
              {text2}
            </Text>
          </div>

          <div className="flex desktop:flex-col justify-between gap-10 mobile:gap-6 items-center desktop:w-[300px] w-full h-full">
            <div
              className={`flex justify-center items-center bg-primary-100 rounded-2xl shadow-200 tablet:rounded-lg desktop:w-[60%] w-[20%] min-w-[60px] h-full`}
            >
              <img
                className="min-w-[40px] min-h-[40px] w-[100%] h-[100%] p-10 tablet:p-4"
                src={process.env.ASSETS_PATH + "/imgs/" + image3}
                alt={text3}
              />
            </div>
            <Text className="max-w-[300px] tablet:max-w-[500px] w-full h-full font-semibold leading-6 text-functional-800">
              {text3}
            </Text>
          </div>
        </div>

        <Link
          to={localeRoutes({ page: "solutions" })[i18n.language]}
          className="flex justify-center items-center w-full z-10"
        >
          <Button className="" size="lg">
            {buttonText}
          </Button>
        </Link>

        <div className="bg-primary-100 animate-boxRandom3 rounded-lg absolute left-[60px] top-[420px] w-[80px] h-[80px] opacity-20 mobile:hidden" />
        <div className="bg-primary-100 animate-boxRandom2 rounded-lg absolute left-[120px] top-[250px] mobile:top-[100px] w-[80px] h-[80px] opacity-20" />

        <div className="bg-primary-100 animate-boxRandom1 rounded-lg absolute right-[540px] top-[260px] w-[160px] h-[160px] opacity-20 mobile:hidden" />

        <div className="bg-primary-100 animate-boxRandom2 rounded-lg absolute right-[200px] top-[275px] w-[150px] h-[150px] opacity-20 mobile:hidden" />
        <div className="bg-primary-100 animate-boxRandom1 rounded-lg absolute right-[60px] top-[420px] w-[80px] h-[80px] opacity-20" />
      </div>
    </div>
  );
};
