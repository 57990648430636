import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

const LocalePath = () => {
  const location = useLocation();
  const lang = location.pathname.split("/")[1];

  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang && i18n.languages.includes(lang)) i18n.changeLanguage(lang);
    else i18n.changeLanguage("br");
  }, [lang]);

  return <Outlet />;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  element: <LocalePath />,
  validateRoute: () => null,
};
