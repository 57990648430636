import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "linktou.ds.icons";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

interface Option {
  label: string;
  value: string | number;
}

export interface ISelectProps {
  label?: string;
  options: Option[];
  defaultValue?: Option;
  onChange?: (option: Option) => void;
  className?: string;
  size?: "sm" | "md";
}

const selectStyles = {
  wrapper: "relative min-w-[150px] w-fit",
  visible: {
    base: "w-full cursor-pointer text-primary-400 font-semibold lowercase bg-functional-100 px-4 flex items-center justify-between gap-4",
    size: {
      sm: "h-[32px]",
      md: "h-[40px]",
    },
  },
  label: {
    base: "absolute text-sm font-semibold text-gray-700",
    size: {
      sm: "-mt-[28px]",
      md: "-mt-[30px]",
    },
  },
  list: "absolute z-10 w-full rounded-lg bg-white shadow-200",
  listItem: {
    base: "cursor-pointer px-4 h-[22px] first:rounded-t-lg last:rounded-b-lg ",
    unSelected: "bg-functional-100 hover:bg-functional-300",
    selected: "bg-primary-400 text-functional-100",
    size: {
      sm: "h-[18px]",
      md: "h-[22px]",
    },
  },
};

const CustomSelect: React.FC<ISelectProps> = ({
  label,
  options,
  defaultValue = { label: "customSelect.label", value: "default" },
  onChange,
  className = "",
  size = "md",
 
}) => {
  const {t} = useTranslation('blog');

  const [selectedOption, setSelectedOption] = useState<Option>(defaultValue ?? options[0]);

  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option: Option) => {
    if (selectedOption !== option) {
      setSelectedOption(option);
    }

    setIsOpen(false);
    if (onChange) onChange(option);
  };

  return (
    <div
      tabIndex={0}
      onBlur={() => setIsOpen(false)}
      className={twMerge(selectStyles.wrapper, className)}
    >
      {label && (
        <label className={`${selectStyles.label.base} ${selectStyles.label.size[size]}`}>
          {label}
        </label>
      )}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={twMerge(
          selectStyles.visible.base,
          selectStyles.visible.size[size],
          isOpen && "border-primary-400",
        )}
      >
        <span>{t(selectedOption.label)}</span>
        {isOpen ? (
          <ChevronUpIcon size={size} color="text-functional-400" />
        ) : (
          <ChevronDownIcon size={size} color="text-functional-400" />
        )}
      </div>
      {isOpen && (
        <div className={selectStyles.list}>
          {options.map((option, index) => {
            return (
              <div
                key={option.value}
                onClick={() => handleOptionClick(option)}
                className={twMerge(
                  selectStyles.listItem.base,
                  selectStyles.listItem.size[size],
                  selectStyles.listItem.unSelected,
                  `select-option-${index}`,
                  option.value === selectedOption.value && selectStyles.listItem.selected,
                )}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
