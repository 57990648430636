import React from "react";
import ReactDOM from "react-dom/client";

import App from "./src/App";

/** ROUTER */
import { BrowserRouter } from "react-router-dom";
/** END ROUTER */

/** THEME */
import './src/theme/index.css';
/** END THEME */

/** REDUX */
import { Provider } from "react-redux";
import store from "./src/store/configureStore";
/** END REDUX */

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<BrowserRouter >
			<App />
		</BrowserRouter>
	</Provider>
);
