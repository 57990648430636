import BlogHomePage from "./src/pages/Blog/Home";
import HomePage from "./src/pages/Home";
import IntegratorPage from "./src/pages/Integrator";
import SolutionsPage from "./src/pages/Solutions";
import SchedulePage from "./src/pages/Schedule";
import ErrorPage from "./src/pages/Error";
import BlogDetailPage from "./src/pages/Blog/Detail";
import LocalePath from "./src/locale/components/LocalePath";

const innerRoutes = [
  {
    index: true,
    ...HomePage,
  },
  {
    path: "blog",
    ...BlogHomePage,
  },
  {
    path: "blog/:category/:slug",
    ...BlogDetailPage,
  },
  {
    path: "integrator",
    ...IntegratorPage,
  },
  {
    path: "solucoes",
    ...SolutionsPage,
  },
  {
    path: "agende-uma-conversa",
    ...SchedulePage,
  },
  {
    path: "*",
    ...ErrorPage,
  },
];

const innerRoutesEN = [
  {
    index: true,
    ...HomePage,
  },
  {
    path: "blog",
    ...BlogHomePage,
  },
  {
    path: "blog/:category/:slug",
    ...BlogDetailPage,
  },
  {
    path: "integrator",
    ...IntegratorPage,
  },
  {
    path: "solutions",
    ...SolutionsPage,
  },
  {
    path: "schedule-a-meeting",
    ...SchedulePage,
  },
  {
    path: "*",
    ...ErrorPage,
  },
];

const innerRoutesES = [
  {
    index: true,
    ...HomePage,
  },
  {
    path: "blog",
    ...BlogHomePage,
  },
  {
    path: "blog/:category/:slug",
    ...BlogDetailPage,
  },
  {
    path: "integrator",
    ...IntegratorPage,
  },
  {
    path: "soluciones",
    ...SolutionsPage,
  },
  {
    path: "agenda-una-conversacion",
    ...SchedulePage,
  },
  {
    path: "*",
    ...ErrorPage,
  },
];

export const Routes = [
  {
    path: "/",
    ...LocalePath,
    children:
      innerRoutes,
  },
  {
    path: "/en",
    ...LocalePath,
    children: innerRoutesEN,
  },
  {
    path: "/es",
    ...LocalePath,
    children: innerRoutesES,
  },
  {
    path: '404',
    ...ErrorPage
  }
];
