import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Autoplay } from "swiper";
import { Heading, Image, Text } from "linktou.ds.components";

import "./Carousel.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { twJoin, twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export interface Content {
  name: string;
  description: string;
  image: string;
  company: string;
}

export const Feedbacks = () => {
  const { t } = useTranslation("home");

  const slides: Content[] = [
    {
      name: t("feedbacks.cayena.contact"),
      description: t("feedbacks.cayena.content"),
      image: process.env.ASSETS_PATH + "/feedbacks/cayena.png",
      company: t("feedbacks.cayena.company"),
    },
    {
      name: t("feedbacks.hiPlatform.contact"),
      description: t("feedbacks.hiPlatform.content"),
      image: process.env.ASSETS_PATH + "/feedbacks/hi.png",
      company: t("feedbacks.hiPlatform.company"),
    },
    {
      name: t("feedbacks.chinaLink.contact"),
      description: t("feedbacks.chinaLink.content"),
      image: process.env.ASSETS_PATH + "/feedbacks/chinaLink.jpeg",
      company: t("feedbacks.chinaLink.company"),
    },
    {
      name: t("feedbacks.amaze.contact"),
      description: t("feedbacks.amaze.content"),
      image: process.env.ASSETS_PATH + "/feedbacks/amaze.png",
      company: t("feedbacks.amaze.company"),
    },
    {
      name: t("feedbacks.cayena.contact"),
      description: t("feedbacks.cayena.content"),
      image: process.env.ASSETS_PATH + "/feedbacks/cayena.png",
      company: t("feedbacks.cayena.company"),
    },
    {
      name: t("feedbacks.hiPlatform.contact"),
      description: t("feedbacks.hiPlatform.content"),
      image: process.env.ASSETS_PATH + "/feedbacks/hi.png",
      company: t("feedbacks.hiPlatform.company"),
    },
    {
      name: t("feedbacks.chinaLink.contact"),
      description: t("feedbacks.chinaLink.content"),
      image: process.env.ASSETS_PATH + "/feedbacks/chinaLink.jpeg",
      company: t("feedbacks.chinaLink.company"),
    },
    {
      name: t("feedbacks.amaze.contact"),
      description: t("feedbacks.amaze.content"),
      image: process.env.ASSETS_PATH + "/feedbacks/amaze.png",
      company: t("feedbacks.amaze.company"),
    },
  ];

  return (
    <div className="relative flex flex-col justify-center items-center bg-functional-100 w-full h-[801px] tablet:justify-center mobile:h-[681px] mobile:mb-[84px] mobile:justify-start">
      <div className="absolute top-[23px] left-[104px] h-[90px] w-[90px] rounded-lg opacity-5 bg-primary-300 tablet:hidden"></div>
      <div className="absolute top-[55px] left-[149px] h-[155px] w-[155px] rounded-lg opacity-5 bg-primary-300 tablet:top-[159px] tablet:-left-[19px] tablet:w-[121px] tablet:h-[121px] mobile:top-[139px]"></div>
      <div className="relative flex flex-col justify-between items-center w-full max-w-[1600px] h-full desktop:px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
        <div className="flex flex-col justify-center items-center w-full h-[721px] pt-[80px] carouselSM:h-[621px] carouselSM:gap-8 carouselSM:px-0 carouselMD:gap-8 carouselMD:w-full carouselLG:gap-12">
          <Heading
            variant="h1"
            className="text-primary-400 text-[36px] tablet:text-[28px] mobile:text-[28px] uppercase font-bold"
          >
            {t("feedbacks.title")}
          </Heading>

          <div className="relative w-full h-fit">
            {typeof document !== "undefined" ? (
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                loop={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                coverflowEffect={{
                  rotate: 0,
                }}
                spaceBetween={120}
                breakpoints={{
                  "480": {
                    slidesPerView: "auto",
                    spaceBetween: 120,
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 0,
                      depth: 0,
                      modifier: 0,
                      scale: 1,
                      slideShadows: true,
                    },
                  },
                  "720": {
                    slidesPerView: "auto",
                    spaceBetween: 50,
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 0,
                      depth: 0,
                      modifier: 0,
                      scale: 1,
                      slideShadows: true,
                    },
                  },
                  "1366": {
                    slidesPerView: "auto",
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 212,
                      depth: 100,
                      modifier: 2,
                      scale: 1,
                      slideShadows: true,
                    },
                  },
                  "1440": {
                    slidesPerView: "auto",
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 253,
                      depth: 100,
                      modifier: 2,
                      scale: 1,
                      slideShadows: true,
                    },
                  },
                  "1920": {
                    slidesPerView: "auto",
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 230,
                      depth: 100,
                      modifier: 2,
                      scale: 1,
                      slideShadows: true,
                    },
                  },
                }}
                pagination={{ el: ".swiper-pagination", clickable: false }}
                modules={[Autoplay, EffectCoverflow, Pagination]}
                className="swiper_container h-[410px] carouselMD:h-[380px] carouselSM:h-[464px]"
              >
                {slides.length > 0 &&
                  slides.map((item, index) => {
                    return (
                      <SwiperSlide
                        key={item.name + index}
                        className={twJoin(
                          "!w-[693px] !h-[400px]",
                          "carouselSM:!w-[100%] carouselSM:!h-[454px]",
                          "carouselMD:!w-[560px] carouselMD:!h-[388px]",
                          "carouselLG:!w-[600px] carouselLG:!h-[400px] py-4",
                          "carouselXL:!w-[693px] carouselXL:!h-[400px]"
                        )}
                      >
                        {({ isPrev, isNext, isActive }) => (
                          <div
                            className={twMerge(
                              `swiper-wrapper bg-[#E3E9EF] text-black rounded-lg shadow-300`,
                              isPrev && "bg-functional-300 shadow-300",
                              isNext && "bg-functional-300 shadow-300",
                              isActive && "bg-functional-300 shadow-300"
                            )}
                          >
                            <div
                              className={twMerge(
                                `w-full h-full flex flex-col blur-[2px] justify-around items-start carouselXL:gap-0 carouselSM:gap-5 px-[32px] carouselSM:px-4`,
                                isNext && "blur-[1px]",
                                isPrev && "blur-[1px]",
                                isActive && "blur-none"
                              )}
                            >
                              <Text className="font-semibold text-lg mobile:text-base">
                                {item.description}
                              </Text>
                              <div className="flex flex-row justify-start items-center gap-4">
                                <Image
                                  className="h-[100px] w-[100px] tablet:w-[80px] tablet:h-[80px] mobile:w-[80px] mobile:h-[80px] rounded-full"
                                  src={item.image}
                                  alt="slide_image"
                                />
                                <div className="flex flex-col justify-center gap-2 text-functional-800 ">
                                  <Text className="font-semibold text-base">
                                    {item.name}
                                  </Text>
                                  <Text className="text-sm">
                                    {item.company}
                                  </Text>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            ) : null}
            <div className="slider-controler">
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
