import { Button, Heading, Text, useIsVisible } from "linktou.ds.components";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";
import { localeRoutes } from "../../../locale/localeRoutes";

export const Ipass = () => {
  const { t, i18n } = useTranslation("home");
  const ref = useRef();
  const isVisible = useIsVisible(ref, true);

  return (
    <div className="relative flex justify-center items-center w-full min-h-[800px] bg-functional-100 px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px] gap-10">
      <div
        ref={ref}
        className="flex tablet:flex-col justify-center items-center w-full max-w-7xl tablet:gap-8"
      >
        <div className="flex flex-col desktop:w-1/2 tablet:w-full gap-8 desktop:pr-[60px] z-10">
          <Heading
            className={twMerge(
              "text-[36px] font-semibold text-primary-400 w-full opacity-0",
              isVisible && "animate-slideUp opacity-100"
            )}
            variant="h2"
          >
            {t("ipaas.title")}
          </Heading>

          <div
            className={twMerge(
              "flex flex-col w-full gap-8 opacity-0",
              isVisible && "animate-slideUp-2 opacity-100"
            )}
          >
            <Heading
              className="text-2xl font-semibold text-primary-400 w-full"
              variant="h3"
            >
              {t("ipaas.item1.title")}
            </Heading>
            <Text className="text-xl w-full">
              {t("ipaas.item1.description")}
            </Text>
          </div>

          <div
            className={twMerge(
              "flex flex-col w-full gap-8 opacity-0",
              isVisible && "animate-slideUp-3 opacity-100"
            )}
          >
            <Heading
              className="text-2xl font-semibold text-primary-400 w-full"
              variant="h3"
            >
              {t("ipaas.item2.title")}
            </Heading>
            <Text className="text-xl w-full">
              {t("ipaas.item2.description")}
            </Text>
          </div>
        </div>
        <div className="flex flex-col desktop:w-1/2 tablet:w-full gap-10 justify-center items-center z-10">
          <img
            className={twMerge(
              "w-full opacity-0",
              isVisible && "animate-slideUp opacity-100"
            )}
            src={process.env.ASSETS_PATH + "/imgs/abbandonedCartPreview.png"}
            alt="Nossa plataforma oferece uma variedade de recursos, como conectores pré-criados para diferentes aplicativos, ferramentas de mapeamento e transformação de dados, gerenciamento de fluxo de trabalho, monitoramento e rastreamento em tempo real, segurança de dados e muito mais. Esses recursos simplificam e aceleram o processo de integração, permitindo que as empresas otimizem suas operações, compartilhem informações de forma eficiente e automatizem fluxos de trabalho para melhorar a produtividade e a eficácia."
          />
          <Link
            to={localeRoutes({ page: "integrator" })[i18n.language]}
            className="w-full flex justify-center items-center z-10"
          >
            <Button size="lg" className="desktop:mt-0 mt-4 mobile:px-0">
              {t("ipaas.cta")}
            </Button>
          </Link>
        </div>

        <div className="bg-primary-100 animate-boxRandom1 rounded-lg absolute right-[17%] top-[12%] tablet:top-[-2%] w-[120px] h-[120px] opacity-10 overflow-hidden" />
        <div className="bg-primary-100 animate-boxRandom1 rounded-lg absolute right-[10%] top-[7%] tablet:top-[-5%] w-[190px] tablet:w-[80px] h-[190px] tablet:h-[80px] opacity-10 overflow-hidden" />

        <div className="bg-primary-100 animate-boxRandom2 rounded-lg absolute left-[-3%] tablet:left-[-5%] bottom-[80%] w-[90px] h-[90px] opacity-10 tablet:hidden" />
        {/* <div className='bg-primary-600 animate-boxRandom3 rounded-2xl absolute right-[7.5%] tablet:left-[-30%] bottom-[5%] w-[300px] h-[300px] opacity-10 overflow-hidden' /> */}
      </div>
    </div>
  );
};
