import banner from "../common/locale/banner.json";
import cards from "../common/locale/cards.json";
import connectors from "../common/locale/connectors.json";
import header from "../layout/locale/header.json";
import footer from "../layout/locale/footer.json";
import customSelect from "../pages/Blog/components/locale/customSelect.json";
import detailContent from "../pages/Blog/components/locale/detailContent.json";
import feed from "../pages/Blog/components/locale/feed.json";
import blogHero from "../pages/Blog/components/locale/hero.json";
import latestPosts from "../pages/Blog/components/locale/latestPosts.json";
import mainPost from "../pages/Blog/components/locale/mainPost.json";
// import detail from '../pages/Blog/Detail/detail.json'
// import home from '../pages/Blog/Home/home.json'

import notFound from "../pages/Error/components/notFound.json";
import brands from "../pages/Home/components/locale/brands.json";
import feedbacks from "../pages/Home/components/locale/feedbacks.json";
import homeHero from "../pages/Home/components/locale/hero.json";
import integrations from "../pages/Home/components/locale/integrations.json";
import ipaas from "../pages/Home/components/locale/ipaas.json";
import article from "../pages/Integrator/components/locale/article.json";
import integratorHero from "../pages/Integrator/components/locale/hero.json";
import frame from "../pages/Schedule/components/locale/frame.json";
import scheduleHero from "../pages/Schedule/components/locale/hero.json";
import articles from "../pages/Solutions/components/locale/articles.json";
import solutionsHero from "../pages/Solutions/components/locale/hero.json";
import meta from "../locale/meta.json";
export var resources = {
  en: {
    //common
    banner: banner["en"],
    cards: cards["en"],
    connectors: connectors["en"],
    //layout
    header: header["en"],
    footer: footer["en"],
    meta: meta["en"],
    // --------- pages --------- //
    error: notFound["en"],
    blog: {
      customSelect: customSelect["en"],
      detailContent: detailContent,
      feed: feed["en"],
      hero: blogHero["en"],
      latestPosts: latestPosts["en"],
      mainPost: mainPost["en"]
      // 'detail': detail,
      // 'home': home,
    },

    home: {
      brands: brands["en"],
      feedbacks: feedbacks["en"],
      hero: homeHero["en"],
      integrations: integrations["en"],
      ipaas: ipaas["en"]
    },
    integrator: {
      article: article["en"],
      hero: integratorHero["en"]
    },
    schedule: {
      frame: frame["en"],
      hero: scheduleHero["en"]
    },
    solutions: {
      article: articles["en"],
      hero: solutionsHero["en"]
    }
  },
  br: {
    //common
    banner: banner["br"],
    cards: cards["br"],
    connectors: connectors["br"],
    //layout
    header: header["br"],
    footer: footer["br"],
    meta: meta["br"],
    // --------- pages --------- //
    error: notFound["br"],
    blog: {
      customSelect: customSelect["br"],
      detailContent: detailContent,
      feed: feed["br"],
      hero: blogHero["br"],
      latestPosts: latestPosts["br"],
      mainPost: mainPost["br"]
      // 'detail': detail,
      // 'home': home,
    },

    home: {
      brands: brands["br"],
      feedbacks: feedbacks["br"],
      hero: homeHero["br"],
      integrations: integrations["br"],
      ipaas: ipaas["br"]
    },
    integrator: {
      article: article["br"],
      hero: integratorHero["br"]
    },
    schedule: {
      frame: frame["br"],
      hero: scheduleHero["br"]
    },
    solutions: {
      article: articles["br"],
      hero: solutionsHero["br"]
    }
  },
  es: {
    //common
    banner: banner["es"],
    cards: cards["es"],
    connectors: connectors["es"],
    //layout
    header: header["es"],
    footer: footer["es"],
    meta: meta["es"],
    // --------- pages --------- //
    error: notFound["es"],
    blog: {
      customSelect: customSelect["es"],
      detailContent: detailContent,
      feed: feed["es"],
      hero: blogHero["es"],
      latestPosts: latestPosts["es"],
      mainPost: mainPost["es"]
      // 'detail': detail,
      // 'home': home,
    },

    home: {
      brands: brands["es"],
      feedbacks: feedbacks["es"],
      hero: homeHero["es"],
      integrations: integrations["es"],
      ipaas: ipaas["es"]
    },
    integrator: {
      article: article["es"],
      hero: integratorHero["es"]
    },
    schedule: {
      frame: frame["es"],
      hero: scheduleHero["es"]
    },
    solutions: {
      article: articles["es"],
      hero: solutionsHero["es"]
    }
  }
};