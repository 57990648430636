import React from "react";
import { NotFound as NotFoundComponent } from "linktou.ds.components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { localeRoutes } from "../../../locale/localeRoutes";

export const NotFound = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation("error");

  return (
    <div className="">
      <NotFoundComponent
        className="py-40"
        headingText={t("title")}
        buttonText={t("cta")}
        onButtonClick={() => navigate(localeRoutes({ page: "home" })[i18n.language])}
      />
    </div>
  );
};
