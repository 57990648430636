import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { localeRoutes } from "../../locale/localeRoutes";

import { Footer, Header } from "../../layout";
import { Helmet } from "react-helmet";
import { Banner } from "../../common/Banner";
import { NotFound } from "./components/NotFound";

const ErrorPage = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation("meta");
  const invalidRoute = location.pathname.replace(`/${i18n.language}`, "");

  return (
    <>
      <Helmet>
        <title>{`${t("error.title", { lng: i18n.language })}`}</title>
      </Helmet>
      <Header routes={localeRoutes({ page: "error", invalidRoute: invalidRoute })} />
      <NotFound />
      <Banner />
      <Footer />
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  element: <ErrorPage />,
  validateRoute: async (dispatch, page, store, path, matchPath) => {
    // await dispatch(fetchHomePage());
    return new Promise((resolve, reject) => {
      if (page.pathname === "/404") resolve({ isValid: true });
      else resolve({ isValid: false });
    });
  },
};
