/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useAppSelector } from "../../../store";
import { Button, Heading, Image, Spinner, Text } from "linktou.ds.components";
import { Link } from "react-router-dom";
import { localeRoutes } from "../../../locale/localeRoutes";
import { TFunction, i18n } from "i18next";

interface MainPostProps {
  t: TFunction<"blog", undefined>;
  i18n: i18n;
}

export const MainPost = ({ t, i18n }: MainPostProps) => {
  const { blogPostDetailFetcher, blogMediaDetailFetcher, blogCategoriesFetcher } = useAppSelector(
    (store) => ({
      blogPostDetailFetcher: store.blogPostDetailFetcher,
      blogMediaDetailFetcher: store.blogMediaDetailFetcher,
      blogCategoriesFetcher: store.blogCategoriesFetcher,
    }),
  );

  const image_url =
    blogMediaDetailFetcher.status === "fulfilled"
      ? blogMediaDetailFetcher.data.media_details.sizes.full.source_url
      : "";
  const slug = blogPostDetailFetcher.status === "fulfilled" ? blogPostDetailFetcher.data.slug : "";

  const categoryName =
    blogCategoriesFetcher.status === "fulfilled" &&
    blogPostDetailFetcher.status === "fulfilled" &&
    blogCategoriesFetcher.data.filter((cat) => cat.id === blogPostDetailFetcher.data!?.categories[0]);

  return blogPostDetailFetcher.status === "fulfilled" && blogMediaDetailFetcher.status === "fulfilled" ? (
    <div className="relative flex mobile:flex-col-reverse justify-around items-center w-full gap-4 px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
      <div className="flex justify-center items-start flex-col text-left w-1/2 mobile:w-full gap-8 text-primary-400">
        <div className="flex flex-col gap-0 w-full">
          <Text className="text-xl mobile:text-md font-semibold text-functional-500">
            {t("mainPost.title")}
          </Text>
          <Link
            to={
              localeRoutes({ page: "blogDetail", slug: slug, category: categoryName[0]!?.slug })[
                i18n.language
              ]
            }
          >
            <Heading variant="h4" className="text-4xl mobile:text-[28px] font-bold">
              {blogPostDetailFetcher.data.title.rendered}
            </Heading>
          </Link>
        </div>
        <Text className="text-lg text-functional-800">{blogPostDetailFetcher.data.yoast_head_json.description}</Text>
        <Link
          to={
            localeRoutes({ page: "blogDetail", slug: slug, category: categoryName[0]!?.slug })[
              i18n.language
            ]
          }
        >
          <Button variant="fill">{t("mainPost.read-more")}</Button>
        </Link>
      </div>

      <div className="flex justify-center items-center z-10 w-1/2 mobile:w-full pl-8 mobile:pl-0">
        <Link
          to={
            localeRoutes({ page: "blogDetail", slug: slug, category: categoryName[0]!?.slug })[
              i18n.language
            ]
          }
        >
          {image_url ? (
            <Image className="w-full tablet:h-[280px] object-cover rounded-lg" src={image_url} alt={slug} />
          ) : (
            <Spinner />
          )}
        </Link>
      </div>

      <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute right-[40%] mobile:right-[90%] bottom-[55%] mobile:bottom-[75%] w-[120px] h-[120px] mobile:h-[100px] mobile:w-[100px] opacity-10 overflow-hidden" />
      <div className="bg-primary-600 animate-boxRandom1 rounded-lg absolute right-[17.5%] mobile:right-[2.5%] bottom-[15%] mobile:bottom-[40%] w-[190px] h-[190px] mobile:h-[160px] mobile:w-[160px] opacity-10 overflow-hidden" />
    
    </div>
  ) : (
    <div className="flex justify-center items-center py-10 min-h-[400px]">
      <Spinner />
    </div>
  );
};
