/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { clearBlogMediaFetcher, fetchLatestPostsMedia } from "../slices/blogMediaFetcherSlice";
import { Post } from "../../../types/blogTypes";
import {
  clearBlogLatestPostsFetcher,
  fetchLatestPosts,
} from "../slices/blogLatestPostsFetcherSlice";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Image,
  Spinner,
  Tag,
  Text,
} from "linktou.ds.components";
import { convertDateToBrazilianFormat } from "../../../utils/utils";
import { localeRoutes } from "../../../locale/localeRoutes";
import { Link } from "react-router-dom";
import { i18n } from "i18next";

interface LatestPostsProps {
  t: any;
  i18n: i18n;
}

export const LatestPosts = ({ t, i18n }: LatestPostsProps) => {
  const dispatch = useAppDispatch();

  const { blogLatestPostsFetcher, blogMediaFetcher, blogCategoriesFetcher } = useAppSelector(
    (state) => ({
      blogLatestPostsFetcher: state.blogLatestPostsFetcher,
      blogMediaFetcher: state.blogMediaFetcher,
      blogCategoriesFetcher: state.blogCategoriesFetcher,
    }),
  );

  const getMediaIdsToFetch = (arr: Post[]) => {
    let str = [];
    for (let post of arr) {
      str.push(String(post.featured_media));
    }
    return str.join(",");
  };

  const matchMediasToLatestPostsState = (state, medias) => {
    let stt = [];
    for (let i = 0; i < state.length; i++) {
      let post = state[i];
      for (let img of blogMediaFetcher.data) {
        if (post.featured_media === img.id) {
          stt.push(
            Object.assign(
              {
                image_url: img.media_details.sizes.medium
                  ? img.media_details.sizes.medium.source_url
                  : img.media_details.sizes.full.source_url,
              },
              state[i],
            ),
          );
        }
      }
    }
    return stt;
  };

  const getPostCategoryArray = (categoriesArr: number[]) => {
    let arr = [];
    if (
      blogCategoriesFetcher.status === "fulfilled" &&
      blogLatestPostsFetcher.status === "fulfilled"
    ) {
      let categories = blogCategoriesFetcher.data;
      for (let c of categories) {
        for (let c2 of categoriesArr) {
          if (c.id === c2 && c.slug !== "uncategorized") {
            arr.push({ slug: c.slug, name: c.name });
          }
        }
      }
    }
    return arr;
  };

  const comparePostAndCategory = (post: Post, cat: string) => {
    return getPostCategoryArray(post.categories).find((c) => c.slug === cat);
  };

  const [latestPosts, setLatestPosts] = useState([]);

  let mediasToFetch =
    blogLatestPostsFetcher.status === "fulfilled"
      ? getMediaIdsToFetch(blogLatestPostsFetcher.data)
      : null;

  useEffect(() => {
    if (blogLatestPostsFetcher.status === "idle") {
      dispatch(fetchLatestPosts());
    } else if (blogLatestPostsFetcher.status === "fulfilled") {
      setLatestPosts(blogLatestPostsFetcher.data);
    }
    return () => {
      if (blogLatestPostsFetcher.status === "fulfilled") dispatch(clearBlogLatestPostsFetcher());
    };
  }, [blogLatestPostsFetcher.status]);

  useEffect(() => {
    if (mediasToFetch && blogMediaFetcher.status === "idle") {
      dispatch(fetchLatestPostsMedia(mediasToFetch));
    } else if (blogMediaFetcher.status === "fulfilled") {
      setLatestPosts(matchMediasToLatestPostsState(latestPosts, blogMediaFetcher.data));
    }
    return () => {
      if (blogMediaFetcher.status === "fulfilled") dispatch(clearBlogMediaFetcher());
    };
  }, [blogLatestPostsFetcher.status, blogMediaFetcher.status]);

  return (
    <div className="flex flex-col justify-center items-center w-full px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
      <div className="flex flex-col justify-center items-center gap-12 tablet:gap-6 w-full full:max-w-7xl">
        <Heading
          variant={"h3"}
          className="text-left text-[28px] w-full text-primary-400 font-semibold"
        >
          {t("latestPosts.title")}
        </Heading>
        <div className="flex mobile:flex-col justify-between items-center gap-6 full:gap-20 tablet:gap-6 w-full tablet:justify-between">
          {blogLatestPostsFetcher.status === "fulfilled" && latestPosts ? (
            latestPosts.map((post, index) => {
              const postCategorySlug = blogCategoriesFetcher.data!?.filter(
                (category) => category.id === post!?.categories[0],
              )[0].slug;

              return (
                <Link
                  to={
                    localeRoutes({
                      page: "blogDetail",
                      slug: post.slug,
                      category: postCategorySlug,
                    })[i18n.language]
                  }
                  className={`tablet:${
                    index === 2 ? "hidden" : null
                  } flex flex-col justify-center items-center gap-4 shadow-md w-[357px] tablet:w-[324px] mobile:w-full h-[420px] tablet:h-[400px] mobile:h-[480px] hover:bg-functional-200 rounded-lg`}
                  key={post.slug}
                >
                  <Card className="relative w-full h-full p-3">
                    <CardHeader className="flex justify-center items-center h-[55%]">
                      <Image
                        src={post.image_url}
                        alt={post.title}
                        loading="lazy"
                        className="object-cover w-full h-full rounded-lg"
                      />
                      <div className="absolute top-[8%] left-[3%] flex justify-start items-center w-full gap-2 flex-wrap px-3">
                        {getPostCategoryArray(post.categories).map((cat) => {
                          return (
                            <Tag
                              className="absolute text-functional-700 bg-primary-100 w-fit h-6 rounded-md"
                              key={cat.name}
                            >
                              {comparePostAndCategory(post, cat.slug).name}
                            </Tag>
                          );
                        })}
                      </div>
                    </CardHeader>
                    <CardBody className="p-4">
                      <Text className="text-primary-400 font-semibold text-xl">
                        {post.title.rendered}
                      </Text>
                    </CardBody>
                    <CardFooter className="px-4 -mt-3">
                      <Text className="text-xs font-normal">
                        {convertDateToBrazilianFormat(post.date, i18n.language)}
                        {/* {post.yoast_head_json.twitter_misc["Est. tempo de leitura"]} */}
                      </Text>
                    </CardFooter>
                  </Card>
                </Link>
              );
            })
          ) : (
            <div className="flex justify-center items-center w-full py-20">
              <Spinner />
            </div>
          )}
        </div>
        <Link
          to={localeRoutes({page: "blog"})[i18n.language]}
          className="-mt-30 flex justify-center items-center w-full"
        >
          <Button variant="outlined" size="lg">
            {t("latestPosts.view-more")}
          </Button>
        </Link>
      </div>
    </div>
  );
};
