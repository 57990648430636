export var getCategoryNumberFromSlug = function getCategoryNumberFromSlug(slug, categories) {
  var category = categories.filter(function (cat) {
    return cat.slug === slug;
  })[0].id;
  return category;
};
export var convertDateToBrazilianFormat = function convertDateToBrazilianFormat(rawDate, lang) {
  var date = new Date(rawDate);
  var language = lang === 'br' ? 'pt-BR' : lang;
  var days = date.toLocaleDateString(language, {
    dateStyle: "long"
  });
  return days;
};

// export const convertDateToBrazilianFormatWithYear = (date: string) => {
//   const options: Intl.DateTimeFormatOptions = {
//     day: "2-digit",
//     month: "long",
//     year: "numeric",
//   };

//   const localeDate = new Date(date);
//   const brazilianDate = localeDate.toLocaleString("br", options);
//   return brazilianDate.split(",")[0];
// };