export var localeRoutes = function localeRoutes(_ref) {
  var page = _ref.page,
    _ref$slug = _ref.slug,
    slug = _ref$slug === void 0 ? "" : _ref$slug,
    _ref$category = _ref.category,
    category = _ref$category === void 0 ? "" : _ref$category,
    _ref$paramCategory = _ref.paramCategory,
    paramCategory = _ref$paramCategory === void 0 ? "" : _ref$paramCategory,
    _ref$invalidRoute = _ref.invalidRoute,
    invalidRoute = _ref$invalidRoute === void 0 ? "" : _ref$invalidRoute;
  return {
    home: {
      br: "/",
      en: "/en",
      es: "/es"
    },
    integrator: {
      br: "/integrator",
      en: "/en/integrator",
      es: "/es/integrator"
    },
    solutions: {
      br: "/solucoes",
      en: "/en/solutions",
      es: "/es/soluciones"
    },
    schedule: {
      br: "/agende-uma-conversa",
      en: "/en/schedule-a-meeting",
      es: "/es/agenda-una-conversacion"
    },
    blog: {
      br: "/blog",
      en: "/en/blog",
      es: "/es/blog"
    },
    blogCategory: {
      br: "/blog?category=".concat(paramCategory),
      en: "/en/blog?category=".concat(paramCategory),
      es: "/es/blog?category=".concat(paramCategory)
    },
    blogDetail: {
      br: "/blog/".concat(category, "/").concat(slug),
      en: "/en/blog/".concat(category, "/").concat(slug),
      es: "/es/blog/".concat(category, "/").concat(slug)
    },
    error: {
      br: "".concat(invalidRoute),
      en: "/en".concat(invalidRoute),
      es: "/es".concat(invalidRoute)
    }
  }[page];
};