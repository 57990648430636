import React, { useEffect } from "react";
import { useAppSelector } from "../../../store";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** COMPONENTS */
import { Divider, Heading, Image, Spinner, Text } from "linktou.ds.components";
import { ChevronRightIcon } from "linktou.ds.icons";
import { localeRoutes } from "../../../locale/localeRoutes";
import { convertDateToBrazilianFormat } from "../../../utils/utils";
/** END COMPONENTS */

export const DetailContent = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("blog");

  const { blogPostDetailFetcher, blogMediaDetailFetcher, blogCategoriesFetcher } = useAppSelector(
    (store) => ({
      blogPostDetailFetcher: store.blogPostDetailFetcher,
      blogMediaDetailFetcher: store.blogMediaDetailFetcher,
      blogCategoriesFetcher: store.blogCategoriesFetcher,
    }),
  );

  const getPostCategoryArray = (categoriesArr: number[]) => {
    let arr: { slug: string; name: string }[] = [];

    let categories = blogCategoriesFetcher.data;
    for (let c of categories) {
      for (let c2 of categoriesArr) {
        if (c.id === c2 && c.slug !== "uncategorized") {
          arr.push({ slug: c.slug, name: c.name });
        }
      }
    }

    return arr;
  };

  const image_url =
    blogMediaDetailFetcher.status === "fulfilled"
      ? blogMediaDetailFetcher.data.media_details.sizes.full.source_url
      : "";

  const createMarkup = (data) => {
    return { __html: data?.content.rendered };
  };

  useEffect(() => {
    if (blogPostDetailFetcher.status === "fulfilled" && blogPostDetailFetcher.error === 404)
      navigate("/404");
  }, [blogPostDetailFetcher.status, blogPostDetailFetcher.error]);

  return blogPostDetailFetcher.status === "fulfilled" &&
    blogPostDetailFetcher.data !== undefined &&
    blogCategoriesFetcher.status === "fulfilled" ? (
    <div className="flex flex-col justify-center items-center w-full px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
      <div className="flex flex-wrap justify-start items-center w-[800px] tablet:w-full gap-4">
        <Link to={localeRoutes({ page: "blog" })[i18n.language]}>Blog</Link>
        <ChevronRightIcon size="sm" />
        <Link
          to={
            localeRoutes({
              page: "blogCategory",
              paramCategory: getPostCategoryArray(blogPostDetailFetcher?.data?.categories)[0]!
                ?.slug,
            })[i18n.language]
          }
        >
          {getPostCategoryArray(blogPostDetailFetcher.data.categories)[0].name}
        </Link>
      </div>
      <Heading
        variant={"h3"}
        className="w-[800px] tablet:w-full text-4xl tablet:text-2xl font-semibold text-primary-400 mt-6"
      >
        {blogPostDetailFetcher.data.title.rendered}
      </Heading>
      <div className="flex justify-between items-center w-[800px] tablet:w-full gap-4 mobile:items-start mobile:gap-4 mobile:flex-col py-10">
        <div className="flex justify-center items-start flex-col text-left w-2/4 mobile:w-full gap-8">
          <Text className="text-xs font-normal text-functional-500">
            {convertDateToBrazilianFormat(blogPostDetailFetcher.data.date, i18n.language)}
            {/* {blogPostDetailFetcher.data.yoast_head_json.twitter_misc["Est. tempo de leitura"]} */}
          </Text>
          <Text className="word-wrap max-w-xl text-functional-500">
            {blogPostDetailFetcher.data.yoast_head_json.description}
          </Text>
        </div>
        <div className="flex justify-center items-center w-2/4 mobile:w-full pl-8 mobile:pl-0">
          {image_url ? (
            <Image
              src={image_url}
              alt={blogPostDetailFetcher.data.slug}
              className="w-[400px] h-[300px] mobile:h-[250px] mobile:w-full object-cover rounded-lg"
            />
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      <Divider className="w-[800px] tablet:w-full" />
      <div
        className="text-functional-500 min-h-[500px] w-[800px] tablet:w-full"
        id={"markup"}
        dangerouslySetInnerHTML={createMarkup(blogPostDetailFetcher.data)}
      />
    </div>
  ) : (
    <div className="flex justify-center items-center py-20 min-h-[400px]">
      <Spinner />
    </div>
  );
};
