import React from "react";

import { Button, Heading, Text } from "linktou.ds.components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { localeRoutes } from "../../../locale/localeRoutes";

export const Hero = () => {
  const { t, i18n } = useTranslation("solutions");

  return (
    <div
      id="root"
      className="flex justify-center items-center w-full min-h-[780px] text-functional-600 bg-functional-200"
    >
      <div
        id="wrapper"
        className="relative w-full h-fit min-h-[780px] flex justify-start items-center full:max-w-[1600px] px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]"
      >
        <div className="flex flex-col desktop:min-h-[300px] tablet:min-h-[330px] tablet:mt-12 justify-between tablet:justify-center items-start gap-8 tablet:gap-12 mobile:gap-8 w-full z-20">
          <div className="desktop:w-[640px] text-left gap-1">
            <Heading
              variant="h1"
              className="text-4xl mobile:text-2xl mobile:max-w-[80%] leading-[42px] font-bold tracking-wide my-0 text-primary-400"
            >
              {t("hero.title")}
            </Heading>
            <Heading
              variant="h2"
              className="text-4xl mobile:text-2xl leading-[52px] font-bold tracking-[0.03em] mobile:w-[90%]"
            >
              {t("hero.sub-title")}
            </Heading>
          </div>
          <Text className="max-w-[40%] tablet:max-w-[80%] text-functional-600 font-semibold">
            {t("hero.description")}
          </Text>
          <Link
            to={localeRoutes({ page: "schedule" })[i18n.language]}
            className="z-10 w-full"
          >
            <Button size="lg">{t("hero.button")}</Button>
          </Link>
        </div>

        <img
          className="tablet:hidden mobile:hidden tablet:-left-[30px] absolute left-[5px] top-[250px] z-10"
          src={process.env.ASSETS_PATH + "/imgs/altHeroLeftLine.png"}
          alt="hero left line"
        />
        <img
          className="desktop:hidden mobile:hidden absolute left-[32px] top-[280px] z-10"
          src={process.env.ASSETS_PATH + "/imgs/altHeroLeftLineTablet.png"}
          alt="hero left line"
        />

        <div className="mobile:hidden flex absolute w-4 h-4 rounded-lg bg-functional-500 left-[125px] tablet:left-[26px] top-[245px] tablet:top-[260px] z-10" />

        <img
          className="tablet:hidden absolute top-[250px] right-[135px] z-10"
          src={process.env.ASSETS_PATH + "/imgs/altHeroPattern.png"}
          alt=""
        />
        <img
          className="desktop:hidden mobile:hidden absolute top-[180px] right-[60px] z-10"
          src={process.env.ASSETS_PATH + "/imgs/altHeroTabletPattern.png"}
          alt=""
        />
        <img
          className="desktop:hidden tablet:hidden mobile:flex absolute top-[180px] right-[40px] z-10"
          src={process.env.ASSETS_PATH + "/imgs/altHeroMobilePattern.png"}
          alt=""
        />

        <div className="flex justify-end h-[80%]">
          {/* NODE VTEX */}
          <div className="animate-altNode1 mobile:hidden bg-functional-100 border-functional-500 border-2 z-20 rounded-md absolute right-[422px] tablet:right-[192px] top-[170px] tablet:top-[650px] w-[105px] h-[105px] flex justify-center items-center">
            <img
              alt="VTEX Integration"
              src={process.env.ASSETS_PATH + "/nodes/vtex.png"}
            />
            <div className="flex absolute w-3 h-3 rounded-lg bg-functional-500 bottom-[-8px] tablet:bottom-[96px]" />
          </div>

          {/* NODE RD */}
          <div className="animate-altNode2 tablet:hidden bg-functional-100 border-functional-500 border-2 z-20 rounded-md absolute right-[290px] top-[322px] w-[85px] h-[85px] flex justify-center items-center">
            <img
              className="w-[80%]"
              alt="RD Station Integration"
              src={process.env.ASSETS_PATH + "/nodes/rdstation.png"}
            />
            <div className="flex absolute w-3 h-3 rounded-lg bg-functional-500 left-[-8px]" />
          </div>

          {/* NODE ZENDESK */}
          <div className="animate-altNode3 bg-functional-100 border-functional-500 border-2 z-20 rounded-md absolute right-[100px] tablet:right-[120px] mobile:right-[70px] top-[260px] tablet:top-[138px] w-[85px] h-[85px] flex justify-center items-center">
            <img
              alt="ZENDESK Integration"
              src={process.env.ASSETS_PATH + "/nodes/zendesk.png"}
            />
            <div className="flex absolute w-3 h-3 rounded-lg bg-functional-500 left-[-8px] tablet:left-[78px]" />
          </div>

          {/* NODE MAGENTO */}
          <div className="animate-altNode4 tablet:hidden mobile:hidden bg-functional-100 border-functional-500 border-2 z-20 rounded-md absolute right-[153px] tablet:right-[60px] top-[490px] tablet:top-[657px] w-[115px] h-[115px] flex justify-center items-center">
            <img
              className="w-[100%]"
              alt="Magento Integration"
              src={process.env.ASSETS_PATH + "/nodes/magento.png"}
            />
            <div className="flex absolute w-3 h-3 rounded-lg bg-functional-500 top-[-8px]" />
          </div>

          {/* NODE PAYPAL */}
          <div className="animate-altNode5 bg-functional-100 border-functional-500 border-2 z-20 rounded-md absolute right-[480px] tablet:right-[20px] mobile:right-[72px] top-[468px] tablet:top-[457px] mobile:top-[656px] w-[160px] h-[160px] tablet:w-[88px] tablet:h-[88px] flex justify-center items-center">
            <img
              className="w-[90%]"
              alt="PAYPAL Integration"
              src={process.env.ASSETS_PATH + "/nodes/paypal.png"}
            />
            <div className="flex absolute w-3 h-3 rounded-lg bg-functional-500 right-[-8px] tablet:right-[34px] tablet:top-[-8px] mobile:top-[36px] mobile:right-[-8px]" />
          </div>
        </div>

        {/* props */}
        <img
          className="absolute tablet:scale-75 right-[220px] tablet:right-[75%] top-[25%] tablet:top-[22%]"
          src={process.env.ASSETS_PATH + "/imgs/bracketsProp2.png"}
          alt=""
        />
        <img
          className="absolute tablet:scale-75 right-[620px] tablet:right-[10%] mobile:right-[70%] bottom-[12.5%] tablet:bottom-[8%] mobile:bottom-[10%]"
          src={process.env.ASSETS_PATH + "/imgs/magnifierProp.png"}
          alt=""
        />

        {/* quadradinhos esquerda */}
        <div className="bg-[#497482] opacity-20 animate-boxRandom1 rounded-lg absolute left-[80px] top-[190px] w-[80px] h-[80px]" />
        <div className="tablet:hidden bg-[#497482] opacity-20 animate-boxRandom2 rounded-lg absolute left-[100px] bottom-[140px] w-[140px] h-[140px]" />

        {/* quadradinhos da direita */}
        <div className="tablet:hidden bg-[#497482] opacity-20 animate-boxRandom3 rounded-3xl absolute right-20 w-[500px] h-[500px]" />

        <div className="bg-[#497482] opacity-20 animate-boxRandom1 rounded-lg absolute right-[550px] bottom-[60px] w-[200px] h-[200px]" />
        <div className="bg-[#497482] opacity-20 animate-boxRandom2 rounded-lg absolute right-[190px] tablet:right-[0px] tablet:left-[-10px] top-[120px] tablet:top-[90px] w-[140px] h-[140px]" />
        <div className="bg-[#497482] opacity-20 animate-boxRandom3 rounded-lg absolute right-[30px] bottom-[320px] tablet:bottom-[60px] w-[95px] h-[95px] tablet:w-[120px] tablet:h-[120px]" />
        <div className="bg-[#497482] opacity-20 animate-boxRandom3 rounded-lg absolute right-[60px] tablet:right-[0px] tablet:left-[-20px] bottom-[280px] tablet:bottom-[10px] w-[85px] h-[85px] tablet:w-[200px] tablet:h-[200px]" />
      </div>
    </div>
  );
};
