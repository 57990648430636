import React, { useRef } from "react";
import { Heading, Image, Text, useIsVisible } from "linktou.ds.components";
import { TFunction } from "i18next";
import { twMerge } from "tailwind-merge";

interface ArticleProps {
  t: TFunction;
}

export const Article2 = ({ t }: ArticleProps) => {

  const ref = useRef();
  const isVisible = useIsVisible(ref, true);

  return (
    <div
      id="root"
      className="w-full h-fit flex flex-col justify-center items-center  px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]"
    >
      <div ref={ref} id="wrapper" className="flex flex-col justify-center items-center full:max-w-7xl">
        <div className="flex tablet:flex-col justify-between items-center min-h-[400px] gap-16 tablet:gap-12">
          <div className={twMerge("flex flex-col justify-center items-start w-1/2 text-left gap-3 mobile:px-0 tablet:w-full opacity-0", isVisible && 'animate-slideUp-2 opacity-100')}>
            <Heading
              variant="h2"
              className="w-full font-semibold text-[28px] tablet:text-2xl text-primary-400 mb-4"
            >
              {t("article.secondArticle.title")}
            </Heading>
            <Text className="text-base text-primary-800">
              {t("article.secondArticle.sub-title")}
            </Text>
          </div>
          <div className={twMerge("flex justify-center items-center w-1/2 tablet:w-full opacity-0", isVisible && 'animate-slideUp-3 opacity-100')}>
            <Image className="" src={process.env.ASSETS_PATH + "/imgs/flowList.png"} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
