import { useTranslation } from "react-i18next";

import { IconButton, Logo, Text } from "linktou.ds.components";
import { InstragramIcon, LinkedinIcon, WhatsappIcon } from "linktou.ds.icons";

import { whatsappPhoneNumber } from "../utils";
import { Link } from "react-router-dom";
import { localeRoutes } from "../locale/localeRoutes";

const Footer = () => {
  const { t, i18n } = useTranslation("footer");

  return (
    <div className="flex flex-col w-full min-h-96 justify-between items-center bg-primary-800 px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
      <div className="w-full h-fit flex flex-col full:max-w-7xl">
        <div className="flex w-full tablet:flex-col gap-10 mb-10">
          <div className="flex flex-col gap-10 w-1/2 tablet:w-full">
            <Logo className={`w-[180px] text-secondary-400`} />
            <Text className="desktop:max-w-[80%] leading-relaxed text-primary-200">
              {t("description")}
            </Text>
            <div className="flex gap-4">
              <a
                href={`https://api.whatsapp.com/send/?phone=${whatsappPhoneNumber}&text&type=phone_number&app_absent=0`}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton colorScheme="secondary" icon={<WhatsappIcon size="sm" />} />
              </a>
              <a
                href={"https://www.linkedin.com/company/linktou/"}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton colorScheme="secondary" icon={<LinkedinIcon size="sm" />} />
              </a>
              <a href={"https://www.instagram.com/linktoubr/"} target="_blank" rel="noreferrer">
                <IconButton colorScheme="secondary" icon={<InstragramIcon size="sm" />} />
              </a>
            </div>
          </div>

          <div className="flex mobile:flex-wrap gap-16 w-1/2 tablet:w-full">
            <div className="flex flex-col gap-6">
              <Text className="text-primary-200">{t("explore.title")}</Text>
              <Link to={localeRoutes({ page: "home" })[i18n.language]}>
                <Text className="text-secondary-400">{t("explore.option1")}</Text>
              </Link>
              <Link to={localeRoutes({ page: "blog" })[i18n.language]}>
                <Text className="text-secondary-400">{t("explore.option2")}</Text>
              </Link>
            </div>

            <div className="flex flex-col gap-6">
              <Text className="text-primary-200">{t("product.title")}</Text>
              <Link to={localeRoutes({ page: "integrator" })[i18n.language]}>
                <Text className="text-secondary-400">{t("product.option1")}</Text>
              </Link>
              <Link to={localeRoutes({ page: "solutions" })[i18n.language]}>
                <Text className="text-secondary-400">{t("product.option2")}</Text>
              </Link>
            </div>

            <div className="flex flex-col gap-6 min-w-[260px]">
              <Text className="text-primary-200">{t("address.title")}</Text>
              <Text className="text-primary-200">{t("address.option1")}</Text>
              <Text className="text-primary-200">{t("address.option2")}</Text>
            </div>
          </div>
        </div>

        <hr className="w-full text-secondary-400" />

        <div className="flex w-full justify-between text-sm pt-10">
          <Text className="text-primary-200">{`©${new Date().getFullYear()} LinkToU. ${t(
            "copyright",
          )}`}</Text>
        </div>
      </div>
    </div>
  );
};

export default Footer;
