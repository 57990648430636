import React from "react";
import { IconButton, Tooltip } from "linktou.ds.components";
import { WhatsappIcon } from "linktou.ds.icons";
import { whatsappPhoneNumber } from "../utils";
import { useTranslation } from "react-i18next";

const WhatsAppButton = () => {
  const { t } = useTranslation("header");

  return (
    <div className="fixed bottom-8 right-12 z-[1500]">
      <Tooltip placement="top" colorScheme="primary" hasArrow label={t("cta")}>
        <a
          href={`https://api.whatsapp.com/send/?phone=${whatsappPhoneNumber}&text&type=phone_number&app_absent=0`}
          target="_blank"
          rel="noreferrer"
        >
          <IconButton size="xl" colorScheme="secondary" icon={<WhatsappIcon size="lg" />} />
        </a>
      </Tooltip>
    </div>
  );
};

export default WhatsAppButton;
