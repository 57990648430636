import { TFunction } from "i18next";
import { Heading, Image, Text, useIsVisible } from "linktou.ds.components";
import React, { useRef } from "react";
import { twMerge } from "tailwind-merge";

interface ArticleProps {
  t: TFunction;
}

export const Article1 = ({ t }: ArticleProps) => {

  const ref = useRef();
  const isVisible = useIsVisible(ref, true);

  return (
    <div className="w-full h-fit flex flex-col justify-center items-center px-[160px] tablet:px-[80px] mobile:px-[24px] py-[80px] tablet:py-[60px]">
      <div ref={ref} id="wrapper" className="flex flex-col justify-center items-center full:max-w-7xl">
        <div className="w-full h-fit flex flex-col gap-12 tablet:gap-[75px]">
          <div className={twMerge("flex flex-col justify-center items-center mobile:gap-4 opacity-0", isVisible && 'animate-slideUp opacity-100')}>
            <Heading
              variant="h2"
              className="font-bold text-3xl text-center tablet:text-[28px] mobile:text-[28px] uppercase text-primary-400 leading-[54px] tablet:leading-[42px] tracking-[0.03em]"
            >
              {t("article.title")}
            </Heading>
            <Text className="text-center font-semibold text-2xl tablet:text-xl text-primary-800 leading-[32px] tablet:leading-[30px] tracking-[0.03em]">
              {t("article.sub-title")}
            </Text>
          </div>
          <div className="flex tablet:flex-col justify-between items-center w-full min-h-[400px] gap-16 tablet:gap-12">
            <div className={twMerge("flex flex-col justify-center items-start w-1/2 text-left gap-3 mobile:px-0 tablet:w-full opacity-0", isVisible && 'animate-slideUp-2 opacity-100')}>
              <Heading
                variant="h2"
                className="w-full font-semibold text-[28px] tablet:text-2xl text-primary-400 mb-4"
              >
                {t("article.firstArticle.title")}
              </Heading>
              <Text className="text-base text-primary-800">
                {t("article.firstArticle.sub-title")}
              </Text>
            </div>
            <div className={twMerge("flex justify-center items-center w-1/2 tablet:w-full opacity-0", isVisible && 'animate-slideUp-3 opacity-100')}>
              <Image
                className=""
                src={process.env.ASSETS_PATH + "/imgs/abbandonedCartPreview.png"}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
