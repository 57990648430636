import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  Button,
  Divider,
  Dropdown,
  IconButton,
  Logo,
  Text,
  useDisclosure,
} from "linktou.ds.components";
import {
  CogIcon,
  HomeIcon,
  MenuIcon,
  NoteIcon,
  PlugIcon,
  WebhookIcon,
} from "linktou.ds.icons";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { localeRoutes } from "../locale/localeRoutes";

const Header = ({ altColors = false, routes }) => {
  const [isColorInverted, setIsColorInverted] = useState(false);
  const [isHamburguerOpen, setIsHamburguerOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation("header");

  //keep typecheck for node crawler on ssr
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 90) setIsColorInverted(true);
      else setIsColorInverted(false);
    });
  }

  const links = [
    {
      label: "Home",
      path: localeRoutes({ page: "home" })[i18n.language],
      icon: <HomeIcon />,
    },
    {
      label: "Integrator",
      path: localeRoutes({ page: "integrator" })[i18n.language],
      icon: <CogIcon />,
    },
    {
      label: t("solutions"),
      path: localeRoutes({ page: "solutions" })[i18n.language],
      icon: <PlugIcon />,
    },
    {
      label: "Blog",
      path: localeRoutes({ page: "blog" })[i18n.language],
      icon: <NoteIcon />,
    },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    if (
      routes[lang] === "/agende-uma-conversa" ||
      routes[lang] === "/en/schedule-a-meeting" ||
      routes[lang] === "/es/agenda-una-conversacion"
    )
      window.location.replace(routes[lang]);
    else navigate(routes[lang]);

    onClose();
  };

  const HamburguerMenu = () => {

    const {
      isOpen: isOpenLangSelectorMobile,
      onOpen: onOpenLangSelectorMobile,
      onClose: onCloseLangSelectorMobile,
    } = useDisclosure();

    useEffect(() => {
      if (typeof document !== "undefined") {
        const disableScroll = (event) => {
          event.preventDefault();
        };

        const enableScroll = () => {
          document.removeEventListener("wheel", disableScroll);
          document.removeEventListener("touchmove", disableScroll);
        };

        document.addEventListener("wheel", disableScroll, { passive: false });
        document.addEventListener("touchmove", disableScroll, {
          passive: false,
        });

        return () => {
          enableScroll();
        };
      }
    }, []);

    const langExtended = {
      br: "Português",
      en: "English",
      es: "Espanõl",
    };

    return (
      <div
        className={`${
          isHamburguerOpen
            ? "flex flex-col justify-start items-center"
            : "hidden"
        } fixed h-full w-full top-[75px] pt-[10%] pb-[75px] px-6 ${
          isColorInverted
            ? "bg-functional-100"
            : altColors
            ? "bg-functional-200"
            : "bg-primary-800"
        } gap-10 text-lg`}
      >
        {links.map(({ path, label, icon }) => {
          return (
            <Link
              to={path}
              key={label}
              className="w-full flex justify-start items-center h-[40px]"
            >
              <div className="flex items-center justify-center mx-4 w-6 text-primary-400">
                {React.cloneElement(icon, {
                  color:
                    location.pathname === path
                      ? isColorInverted || altColors
                        ? "text-primary-400"
                        : "text-secondary-400"
                      : isColorInverted || altColors
                      ? "text-functional-900"
                      : "text-functional-100",
                })}
              </div>
              <Text
                className={`text-xl ${
                  location.pathname === path
                    ? isColorInverted || altColors
                      ? "text-primary-400 underline font-bold"
                      : "text-secondary-400 underline font-bold"
                    : isColorInverted || altColors
                    ? "text-functional-900"
                    : "text-functional-100"
                }`}
              >
                {label}
              </Text>
            </Link>
          );
        })}

        <Divider orientation="horizontal" />

        <div className="flex w-full h-20">
          <Dropdown
          className="w-full"
            trigger={
              <div
                onClick={onOpenLangSelectorMobile}
                className="flex w-full justify-start items-center gap-2 text-xl h-10 mx-4"
              >
                <WebhookIcon
                  color={`mx-4${
                    isColorInverted
                      ? `bg-white text-functional-600`
                      : `bg-transparent ${
                          altColors
                            ? "text-functional-600"
                            : "text-functional-100"
                        }`
                  }`}
                />
                <Text
                  className={`font-semibold ml-2 ${
                    isColorInverted
                      ? `bg-white text-functional-600`
                      : `bg-transparent ${
                          altColors
                            ? "text-functional-600"
                            : "text-functional-100"
                        }`
                  }`}
                >
                  {i18n.language.toUpperCase() +" - "+langExtended[i18n.language] }
                </Text>
              </div>
            }
            isOpen={isOpenLangSelectorMobile}
            onClose={onCloseLangSelectorMobile}
          >
            <Dropdown.List
              className={
                "mt-3 shadow-300 rounded-lg bg-functional-100 w-[250px] gap-1"
              }
            >
              {i18n.languages.map((lang) => {
                return (
                  <li
                    key={lang}
                    className={twMerge(
                      "flex w-full justify-start items-center snap-center snap-always",
                      "h-[40px] px-4 text-xl text-functional-600 font-medium",
                      "cursor-pointer first-of-type:rounded-tr-lg first-of-type:rounded-tl-lg",
                      "last-of-type:rounded-br-lg last-of-type:rounded-bl-lg",
                      "hover:bg-functional-300 hover:shadow-base hover:text-primary-500",
                      i18n.language === lang
                        ? "bg-primary-400 text-functional-100"
                        : ""
                    )}
                    onClick={() => handleChangeLanguage(lang)}
                  >
                    {`${lang.toUpperCase()} - ${langExtended[lang]}`}
                  </li>
                );
              })}
            </Dropdown.List>
          </Dropdown>
        </div>

        <div className="flex flex-col justify-center items-center w-[80%] gap-6">
          <a
            href="https://app.linktou.com.br/integrator"
            target="_blank"
            rel="noreferrer"
            className="w-full"
          >
            <Button
              colorScheme={
                isColorInverted || altColors ? "primary" : "secondary"
              }
              variant="outlined"
              size="lg"
              isFull
            >
              Login
            </Button>
          </a>

          <Link
            to={localeRoutes({ page: "schedule" })[i18n.language]}
            className="w-full flex justify-center items-center"
          >
            <Button
              colorScheme={
                isColorInverted || altColors ? "primary" : "secondary"
              }
              size="lg"
              isFull
            >
              {t("cta")}
            </Button>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <nav
      className={`z-[300] fixed flex w-full h-20 justify-between items-center gap-4 ${
        isColorInverted
          ? `bg-white text-funcional-800`
          : `bg-transparent ${
              altColors ? "text-functional-800" : "text-functional-100"
            }`
      }`}
    >
      <Link
        to={localeRoutes({ page: "home" })[i18n.language]}
        className={"ml-[5%]"}
      >
        <Logo
          className={`w-[140px] ${
            isColorInverted || altColors
              ? "text-functional-800"
              : "text-secondary-400"
          }`}
        />
      </Link>

      <div className="tablet:hidden flex justify-center items-center gap-8 mr-[5%]">
        {links.map(({ path, label }) => {
          return (
            <Link to={path} key={label}>
              <Button
                variant="ghost"
                colorScheme={
                  isColorInverted || altColors ? "primary" : "secondary"
                }
                className={`leading-relaxed ${
                  location.pathname === path
                    ? isColorInverted || altColors
                      ? " underline font-bold"
                      : " underline font-bold"
                    : isColorInverted || altColors
                    ? "text-functional-600"
                    : "text-functional-100"
                }`}
              >
                {label}
              </Button>
            </Link>
          );
        })}
        |
        <Dropdown
          trigger={
            <div
              onClick={onOpen}
              className="flex justify-center items-center gap-2 text-sm"
            >
              <WebhookIcon
                size="sm"
                color={`${
                  isColorInverted
                    ? `bg-white text-functional-600`
                    : `bg-transparent ${
                        altColors
                          ? "text-functional-600"
                          : "text-functional-100"
                      }`
                }`}
              />
              <Text
                className={`font-semibold ${
                  isColorInverted
                    ? `bg-white text-functional-600`
                    : `bg-transparent ${
                        altColors
                          ? "text-functional-600"
                          : "text-functional-100"
                      }`
                }`}
              >
                {i18n.language.toUpperCase()}
              </Text>
            </div>
          }
          isOpen={isOpen}
          onClose={onClose}
        >
          <Dropdown.List
            className={
              "absolute z-10 mt-3 shadow-300 rounded-lg bg-functional-100 max-h-[300px] w-[150px] ga-1"
            }
          >
            {i18n.languages.map((lang) => {
              const langExtended = {
                br: "Português",
                en: "English",
                es: "Espanõl",
              };

              return (
                <li
                  key={lang}
                  className={twMerge(
                    "flex justify-start items-center snap-center snap-always",
                    "h-[22px] px-4 text-sm text-functional-600 font-medium",
                    "cursor-pointer first-of-type:rounded-tr-lg first-of-type:rounded-tl-lg",
                    "last-of-type:rounded-br-lg last-of-type:rounded-bl-lg",
                    "hover:bg-functional-300 hover:shadow-base hover:text-primary-500",
                    i18n.language === lang
                      ? "bg-primary-400 text-functional-100"
                      : ""
                  )}
                  onClick={() => handleChangeLanguage(lang)}
                >
                  {`${lang.toUpperCase()} - ${langExtended[lang]}`}
                </li>
              );
            })}
          </Dropdown.List>
        </Dropdown>
        <a
          href="https://app.linktou.com.br/integrator"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            colorScheme={isColorInverted || altColors ? "primary" : "secondary"}
            variant="outlined"
            size="md"
            isFitContent
          >
            Login
          </Button>
        </a>
        <Link to={localeRoutes({ page: "schedule" })[i18n.language]}>
          <Button
            colorScheme={isColorInverted || altColors ? "primary" : "secondary"}
          >
            {t("cta")}
          </Button>
        </Link>
      </div>

      <IconButton
        variant="outlined"
        size="lg"
        icon={
          <MenuIcon
            color={`${
              isColorInverted || altColors
                ? "text-primary-500"
                : "text-secondary-500"
            }`}
          />
        }
        onClick={() => setIsHamburguerOpen(!isHamburguerOpen)}
        className={`desktop:hidden mr-[5%] `}
        colorScheme={isColorInverted || altColors ? "primary" : "secondary"}
      ></IconButton>

      {isHamburguerOpen ? <HamburguerMenu /> : null}
    </nav>
  );
};

export default Header;
